import React from 'react'
import { useNavigate } from 'react-router-dom'; // for navigation
import { toast } from 'react-toastify'; // for toast notifications
import Auth from '../component/auth';

function More() {

  const navigate = useNavigate(); // for navigation

  const logoutAction = () => {
    // Clear all stored data
    localStorage.removeItem('deviceToken'); // remove the device token
    // You might want to clear other local storage items if needed
    // localStorage.removeItem('someOtherItem');

    // Optionally clear cookies or session storage
    // document.cookie = 'myCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'; // example for cookies
    sessionStorage.clear(); // clear session storage

    // Show a toast notification
    toast.success('Logged out successfully!');

    // Redirect to the wallet window (or any other route)
    navigate('/login'); // ensure this route is defined in your router setup
  };

  
  return (
    <>
    <Auth/>
  <div className="header fixed-top">
    <div className="left">
      <a href="javascript:void(0);" className="icon back-btn">
        <i className="icon-arrow-right" />
      </a>
    </div>
    <h3>More</h3>
  </div>
  <div className="app-content">
    <div className="tf-container">
      <ul className="list-view check-list">
        {/* <li className="item active">
          <a href="#myWallet" data-bs-toggle="offcanvas" aria-controls="offcanvasRight">
            <div className="box-icon w-48 primary round">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.97 16.08C20.73 18.75 18.8 20.5 16 20.5H7C4.24 20.5 2 18.26 2 15.5V8.5C2 5.78 3.64 3.88 6.19 3.56C6.45 3.52 6.72 3.5 7 3.5H16C16.26 3.5 16.51 3.51 16.75 3.55C19.14 3.83 20.76 5.5 20.97 7.92C21 8.21 20.76 8.45 20.47 8.45H18.92C17.96 8.45 17.07 8.82 16.43 9.48C15.67 10.22 15.29 11.26 15.38 12.3C15.54 14.12 17.14 15.55 19.04 15.55H20.47C20.76 15.55 21 15.79 20.97 16.08Z"
                  fill="url(#paint0_linear_2932_1769)"
                />
                <path
                  d="M22.0002 10.9692V13.0292C22.0002 13.5792 21.5602 14.0292 21.0002 14.0492H19.0402C17.9602 14.0492 16.9702 13.2592 16.8802 12.1792C16.8202 11.5492 17.0602 10.9592 17.4802 10.5492C17.8502 10.1692 18.3602 9.94922 18.9202 9.94922H21.0002C21.5602 9.96922 22.0002 10.4192 22.0002 10.9692Z"
                  fill="url(#paint1_linear_2932_1769)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2932_1769"
                    x1="20.9725"
                    y1="20.5"
                    x2="-1.18128"
                    y2="13.3314"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#7F2DFF" />
                    <stop offset={1} stopColor="#9958FF" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2932_1769"
                    x1="22.0002"
                    y1="14.0492"
                    x2="16.1532"
                    y2="11.9284"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#7F2DFF" />
                    <stop offset={1} stopColor="#9958FF" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <p className="content">My Wallet</p>
            <svg
              className="icon-arr-dark"
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.6">
                <path
                  d="M7.4248 16.6004L12.8581 11.1671C13.4998 10.5254 13.4998 9.47539 12.8581 8.83372L7.4248 3.40039"
                  stroke="#1A1528"
                  strokeWidth="1.5"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </a>
        </li> */}
        <li className="item">
          <a href="/Tasks">
            <div className="box-icon w-48 orange round">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.6704 2H16.7704C14.5904 2 13.4404 3.15 13.4404 5.33V7.23C13.4404 9.41 14.5904 10.56 16.7704 10.56H18.6704C20.8504 10.56 22.0004 9.41 22.0004 7.23V5.33C22.0004 3.15 20.8504 2 18.6704 2Z"
                  fill="url(#paint0_linear_2932_1753)"
                />
                <path
                  d="M7.24 13.4297H5.34C3.15 13.4297 2 14.5797 2 16.7597V18.6597C2 20.8497 3.15 21.9997 5.33 21.9997H7.23C9.41 21.9997 10.56 20.8497 10.56 18.6697V16.7697C10.57 14.5797 9.42 13.4297 7.24 13.4297Z"
                  fill="#FDA222"
                />
                <path
                  d="M6.29 10.58C8.6593 10.58 10.58 8.6593 10.58 6.29C10.58 3.9207 8.6593 2 6.29 2C3.9207 2 2 3.9207 2 6.29C2 8.6593 3.9207 10.58 6.29 10.58Z"
                  fill="#FDA222"
                />
                <path
                  d="M17.7099 21.9999C20.0792 21.9999 21.9999 20.0792 21.9999 17.7099C21.9999 15.3406 20.0792 13.4199 17.7099 13.4199C15.3406 13.4199 13.4199 15.3406 13.4199 17.7099C13.4199 20.0792 15.3406 21.9999 17.7099 21.9999Z"
                  fill="#FDA222"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2932_1753"
                    x1="22.0004"
                    y1="10.56"
                    x2="11.8148"
                    y2="7.60677"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FB9400" />
                    <stop offset={1} stopColor="#FFAB38" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <p className="content">My Task</p>
            <svg
              className="icon-arr-dark"
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.6">
                <path
                  d="M7.4248 16.6004L12.8581 11.1671C13.4998 10.5254 13.4998 9.47539 12.8581 8.83372L7.4248 3.40039"
                  stroke="#1A1528"
                  strokeWidth="1.5"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </a>
        </li>
        {/* <li className="item">
          <a
            href="#watchlist"
            data-bs-toggle="offcanvas"
            aria-controls="offcanvasRight"
          >
            <div className="box-icon w-48 green round">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969ZM12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397Z"
                  fill="url(#paint0_linear_2932_1746)"
                />
                <path
                  d="M12.0004 9.14062C10.4304 9.14062 9.15039 10.4206 9.15039 12.0006C9.15039 13.5706 10.4304 14.8506 12.0004 14.8506C13.5704 14.8506 14.8604 13.5706 14.8604 12.0006C14.8604 10.4306 13.5704 9.14062 12.0004 9.14062Z"
                  fill="url(#paint1_linear_2932_1746)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2932_1746"
                    x1={22}
                    y1="20.5597"
                    x2="-1.14637"
                    y2="12.7242"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#07BD74" />
                    <stop offset={1} stopColor="#1EE495" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2932_1746"
                    x1="14.8604"
                    y1="14.8506"
                    x2="8.066"
                    y2="12.8807"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#07BD74" />
                    <stop offset={1} stopColor="#1EE495" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <p className="content">Watchlist</p>
            <svg
              className="icon-arr-dark"
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.6">
                <path
                  d="M7.4248 16.6004L12.8581 11.1671C13.4998 10.5254 13.4998 9.47539 12.8581 8.83372L7.4248 3.40039"
                  stroke="#1A1528"
                  strokeWidth="1.5"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </a>
        </li> */}
        <li className="item">
          <a href="/setting">
            <div className="box-icon w-48 blue round">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.1 9.21945C18.29 9.21945 17.55 7.93945 18.45 6.36945C18.97 5.45945 18.66 4.29945 17.75 3.77945L16.02 2.78945C15.23 2.31945 14.21 2.59945 13.74 3.38945L13.63 3.57945C12.73 5.14945 11.25 5.14945 10.34 3.57945L10.23 3.38945C9.78 2.59945 8.76 2.31945 7.97 2.78945L6.24 3.77945C5.33 4.29945 5.02 5.46945 5.54 6.37945C6.45 7.93945 5.71 9.21945 3.9 9.21945C2.86 9.21945 2 10.0694 2 11.1194V12.8794C2 13.9194 2.85 14.7794 3.9 14.7794C5.71 14.7794 6.45 16.0594 5.54 17.6294C5.02 18.5394 5.33 19.6994 6.24 20.2194L7.97 21.2094C8.76 21.6794 9.78 21.3995 10.25 20.6094L10.36 20.4194C11.26 18.8494 12.74 18.8494 13.65 20.4194L13.76 20.6094C14.23 21.3995 15.25 21.6794 16.04 21.2094L17.77 20.2194C18.68 19.6994 18.99 18.5294 18.47 17.6294C17.56 16.0594 18.3 14.7794 20.11 14.7794C21.15 14.7794 22.01 13.9294 22.01 12.8794V11.1194C22 10.0794 21.15 9.21945 20.1 9.21945ZM12 15.2494C10.21 15.2494 8.75 13.7894 8.75 11.9994C8.75 10.2094 10.21 8.74945 12 8.74945C13.79 8.74945 15.25 10.2094 15.25 11.9994C15.25 13.7894 13.79 15.2494 12 15.2494Z"
                  fill="url(#paint0_linear_2932_1673)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2932_1673"
                    x1="2.43032"
                    y1="3.53699"
                    x2="25.4824"
                    y2="12.7473"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#5786FF" />
                    <stop offset={1} stopColor="#2D68FF" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <p className="content">Settings</p>
            <svg
              className="icon-arr-dark"
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.6">
                <path
                  d="M7.4248 16.6004L12.8581 11.1671C13.4998 10.5254 13.4998 9.47539 12.8581 8.83372L7.4248 3.40039"
                  stroke="#1A1528"
                  strokeWidth="1.5"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </a>
        </li>
        <li className="item">
          <a
            href="#logout"
            data-bs-toggle="offcanvas"
            data-bs-target="#logout"
            aria-controls="offcanvasBottom"
            className="line"
          >
            <div className="box-icon w-48 error round">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.2 22L9.8 22C13 22 15 20 15 16.8L15 12.75L8.75 12.75C8.34 12.75 8 12.41 8 12C8 11.59 8.34 11.25 8.75 11.25L15 11.25L15 7.2C15 4 13 2 9.8 2L7.21 2C4.01 2 2.01 4 2.01 7.2L2.01 16.8C2 20 4 22 7.2 22Z"
                  fill="url(#paint0_linear_2932_6794)"
                />
                <path
                  d="M19.4396 12.7502L17.3696 14.8202C17.2196 14.9702 17.1496 15.1602 17.1496 15.3502C17.1496 15.5402 17.2196 15.7402 17.3696 15.8802C17.6596 16.1702 18.1396 16.1702 18.4296 15.8802L21.7796 12.5302C22.0696 12.2402 22.0696 11.7602 21.7796 11.4702L18.4296 8.12016C18.1396 7.83016 17.6596 7.83015 17.3696 8.12016C17.0796 8.41016 17.0796 8.89016 17.3696 9.18016L19.4396 11.2502L14.9996 11.2502L14.9996 12.7502L19.4396 12.7502Z"
                  fill="#FB5556"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2932_6794"
                    x1="2.00996"
                    y1={2}
                    x2="18.1925"
                    y2="5.04744"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FF4D67" />
                    <stop offset={1} stopColor="#FF8A9B" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <p className="content">Logout</p>
            <svg
              className="icon-arr-dark"
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.6">
                <path
                  d="M7.4248 16.6004L12.8581 11.1671C13.4998 10.5254 13.4998 9.47539 12.8581 8.83372L7.4248 3.40039"
                  stroke="#1A1528"
                  strokeWidth="1.5"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </a>
        </li>
      </ul>
      <div className="mt-32">
        <h4>Stay Connected With us :</h4>
        <ul className="d-flex gap-15 mt-24">
          <li>
            <a href="#" className="box-icon social website">
              <i className="icon icon-website" />
            </a>
          </li>
          <li>
            <a href="#" className="box-icon social twitter">
              <i className="icon icon-twitter" />
            </a>
          </li>
          <li>
            <a href="#" className="box-icon social discord">
              <i className="icon icon-discord" />
            </a>
          </li>
          <li>
            <a href="#" className="box-icon social instagram">
              <i className="icon icon-instagram" />
            </a>
          </li>
          <li>
            <a href="#" className="box-icon social telegram">
              <i className="icon icon-telegram" />
            </a>
          </li>
          <li>
            <a href="#" className="box-icon social medium">
              <i className="icon icon-medium" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div className="menubar-footer footer-fixed">
    <ul className="inner-bar">
      <li>
        <a href="/">
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.02 2.84016L3.63 7.04016C2.73 7.74016 2 9.23016 2 10.3602V17.7702C2 20.0902 3.89 21.9902 6.21 21.9902H17.79C20.11 21.9902 22 20.0902 22 17.7802V10.5002C22 9.29016 21.19 7.74016 20.2 7.05016L14.02 2.72016C12.62 1.74016 10.37 1.79016 9.02 2.84016Z"
              stroke="#1A1528"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 17.9902V14.9902"
              stroke="#1A1528"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      </li>
      <li>
        <a href="/statistics">
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.6">
              <path
                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                stroke="#1A1528"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.33008 14.4898L9.71008 11.3998C10.0501 10.9598 10.6801 10.8798 11.1201 11.2198L12.9501 12.6598C13.3901 12.9998 14.0201 12.9198 14.3601 12.4898L16.6701 9.50977"
                stroke="#1A1528"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        </a>
      </li>
      <li className="action-add-wallet">
            <a href="/wallet">
              {/* <i className="icon-plus" /> */}
              {/* <i className="bi bi-wallet2"></i> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-wallet2" viewBox="0 0 16 16">
                <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z" />
              </svg>
              {/* <img src="assets/images/wallet/wallet1.png"  alt="wallet image" /> */}

            </a>
          </li>
      <li>
        <a href="/profile">
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.6">
              <path
                d="M12.1596 10.87C12.0596 10.86 11.9396 10.86 11.8296 10.87C9.44957 10.79 7.55957 8.84 7.55957 6.44C7.55957 3.99 9.53957 2 11.9996 2C14.4496 2 16.4396 3.99 16.4396 6.44C16.4296 8.84 14.5396 10.79 12.1596 10.87Z"
                stroke="#1A1528"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.15973 14.56C4.73973 16.18 4.73973 18.82 7.15973 20.43C9.90973 22.27 14.4197 22.27 17.1697 20.43C19.5897 18.81 19.5897 16.17 17.1697 14.56C14.4297 12.73 9.91973 12.73 7.15973 14.56Z"
                stroke="#1A1528"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        </a>
      </li>
      <li className="active">
        <a href="/more">
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.24 2H5.34C3.15 2 2 3.15 2 5.33V7.23C2 9.41 3.15 10.56 5.33 10.56H7.23C9.41 10.56 10.56 9.41 10.56 7.23V5.33C10.57 3.15 9.42 2 7.24 2Z"
              fill="#7F52FF"
            />
            <path
              d="M18.6704 2H16.7704C14.5904 2 13.4404 3.15 13.4404 5.33V7.23C13.4404 9.41 14.5904 10.56 16.7704 10.56H18.6704C20.8504 10.56 22.0004 9.41 22.0004 7.23V5.33C22.0004 3.15 20.8504 2 18.6704 2Z"
              fill="#7F52FF"
            />
            <path
              d="M18.6704 13.4297H16.7704C14.5904 13.4297 13.4404 14.5797 13.4404 16.7597V18.6597C13.4404 20.8397 14.5904 21.9897 16.7704 21.9897H18.6704C20.8504 21.9897 22.0004 20.8397 22.0004 18.6597V16.7597C22.0004 14.5797 20.8504 13.4297 18.6704 13.4297Z"
              fill="#7F52FF"
            />
            <path
              d="M7.24 13.4297H5.34C3.15 13.4297 2 14.5797 2 16.7597V18.6597C2 20.8497 3.15 21.9997 5.33 21.9997H7.23C9.41 21.9997 10.56 20.8497 10.56 18.6697V16.7697C10.57 14.5797 9.42 13.4297 7.24 13.4297Z"
              fill="#7F52FF"
            />
          </svg>
          <span className="text">More</span>
        </a>
      </li>
    </ul>
  </div>
  {/*wallet  */}
  {/* <div className="offcanvas offcanvas-end full" id="myWallet">
    <div className="header fixed-top">
      <div className="left">
        <a
          href="javascript:void(0);"
          data-bs-dismiss="offcanvas"
          className="icon-close"
        >
          <i className="icon-close2" />
        </a>
      </div>
      <h3>My Wallet</h3>
      <div className="right">
        <a href="javascript:void(0);">
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
              stroke="#1A1528"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.9965 12H16.0054"
              stroke="#1A1528"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.9955 12H12.0045"
              stroke="#1A1528"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.99451 12H8.00349"
              stroke="#1A1528"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      </div>
    </div>
    <div className="overflow-auto app-content style-1">
      <div className="tf-container">
        <div className="pb-24 line d-flex flex-column align-items-center box-wallet">
          <div className="avatar round avt-110">
            <img
              className="lazyload"
              data-src="assets/images/avt/avt-wallet.png"
              alt="img"
            />
          </div>
          <div className="mt-20 box-content text-center">
            <h4>Beacon_NFT</h4>
            <span className="button-2 mt-12 d-block ">$410,885.95</span>
          </div>
          <div className="value-field-wallet mt-16">
            <img src="assets/images/avt/metamask.png" alt="metamask" />
            <input type="text" defaultValue="7EK933L1...C93676P7Q4" />
            <svg
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.1665 11.1673V13.6673C14.1665 17.0007 12.8332 18.334 9.49984 18.334H6.33317C2.99984 18.334 1.6665 17.0007 1.6665 13.6673V10.5007C1.6665 7.16732 2.99984 5.83398 6.33317 5.83398H8.83317"
                stroke="#1A1528"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.1663 11.1673H11.4997C9.49967 11.1673 8.83301 10.5007 8.83301 8.50065V5.83398L14.1663 11.1673Z"
                stroke="#1A1528"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.6665 1.66602H12.9998"
                stroke="#1A1528"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.8335 4.16602C5.8335 2.78268 6.95016 1.66602 8.3335 1.66602H10.5168"
                stroke="#1A1528"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.3339 6.66602V11.8243C18.3339 13.116 17.2839 14.166 15.9922 14.166"
                stroke="#1A1528"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.3335 6.66602H15.8335C13.9585 6.66602 13.3335 6.04102 13.3335 4.16602V1.66602L18.3335 6.66602Z"
                stroke="#1A1528"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <ul className="py-20 px-16 box-service line">
          <li>
            <a href="#" className="item">
              <div className="box-icon w-48 round">
                <span className="icon icon-import" />
              </div>
              <span className="fw-6 text-dark">Receive</span>
            </a>
          </li>
          <li>
            <a href="#" className="item">
              <div className="box-icon w-48 round">
                <span className="icon icon-bag-2" />
              </div>
              <span className="fw-6 text-dark">Buy</span>
            </a>
          </li>
          <li>
            <a href="#" className="item">
              <div className="box-icon w-48 round">
                <span className="icon icon-send-2" />
              </div>
              <span className="fw-6 text-dark">Send</span>
            </a>
          </li>
          <li>
            <a href="#" className="item">
              <div className="box-icon w-48 round">
                <span className="icon icon-arrow-swap" />
              </div>
              <span className="fw-6 text-dark">Swap</span>
            </a>
          </li>
        </ul>
        <div className="mt-24">
          <div className="tab-slide wrapper-tab">
            <ul className="nav nav-tabs tab-1" role="tablist">
              <li className="item-slide-effect" />
              <li className="nav-item active" role="presentation">
                <button
                  className="nav-link active"
                  data-bs-toggle="tab"
                  data-bs-target="#tokens"
                >
                  Tokens
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#NFTs"
                >
                  NFTs
                </button>
              </li>
            </ul>
          </div>
          <div className="tab-content mt-24">
            <div
              className="tab-pane fade active show"
              id="tokens"
              role="tabpanel"
            >
              <ul className="list-view check-list">
                <li className="item">
                  <a href="nft-item-details.html" className="gap-12">
                    <div className="image">
                      <img src="assets/images/wallet/wallet-10.png" alt="wallet" />
                    </div>
                    <div className="content">
                      <div className="body-1">324.67539 ETH</div>
                      <p className="body-4 mt-4">$410,750.45</p>
                    </div>
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.6">
                        <path
                          d="M7.4248 16.6004L12.8581 11.1671C13.4998 10.5254 13.4998 9.47539 12.8581 8.83372L7.4248 3.40039"
                          stroke="#1A1528"
                          strokeWidth="1.5"
                          strokeMiterlimit={10}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </a>
                </li>
                <li className="item">
                  <a href="nft-item-details.html" className="gap-12">
                    <div className="image">
                      <img src="assets/images/wallet/wallet-12.png" alt="wallet" />
                    </div>
                    <div className="content">
                      <div className="body-1">36.348508 USDC</div>
                      <p className="body-4 mt-4">$36.4212</p>
                    </div>
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.6">
                        <path
                          d="M7.4248 16.6004L12.8581 11.1671C13.4998 10.5254 13.4998 9.47539 12.8581 8.83372L7.4248 3.40039"
                          stroke="#1A1528"
                          strokeWidth="1.5"
                          strokeMiterlimit={10}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </a>
                </li>
                <li className="item">
                  <a href="nft-item-details.html" className="gap-12">
                    <div className="image">
                      <img src="assets/images/wallet/wallet-11.png" alt="wallet" />
                    </div>
                    <div className="content">
                      <div className="body-1">58.487037 USDT</div>
                      <p className="body-4 mt-4">$36.4212</p>
                    </div>
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.6">
                        <path
                          d="M7.4248 16.6004L12.8581 11.1671C13.4998 10.5254 13.4998 9.47539 12.8581 8.83372L7.4248 3.40039"
                          stroke="#1A1528"
                          strokeWidth="1.5"
                          strokeMiterlimit={10}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-pane fade" id="NFTs" role="tabpanel">
              <ul className="list-view check-list">
                <li className="item">
                  <a href="nft-item-details.html" className="gap-12">
                    <div className="image">
                      <img src="assets/images/wallet/wallet-10.png" alt="wallet" />
                    </div>
                    <div className="content">
                      <div className="body-1">324.67539 ETH</div>
                      <p className="body-4 mt-4">$410,750.45</p>
                    </div>
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.6">
                        <path
                          d="M7.4248 16.6004L12.8581 11.1671C13.4998 10.5254 13.4998 9.47539 12.8581 8.83372L7.4248 3.40039"
                          stroke="#1A1528"
                          strokeWidth="1.5"
                          strokeMiterlimit={10}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </a>
                </li>
                <li className="item">
                  <a href="nft-item-details.html" className="gap-12">
                    <div className="image">
                      <img src="assets/images/wallet/wallet-12.png" alt="wallet" />
                    </div>
                    <div className="content">
                      <div className="body-1">36.348508 USDC</div>
                      <p className="body-4 mt-4">$36.4212</p>
                    </div>
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.6">
                        <path
                          d="M7.4248 16.6004L12.8581 11.1671C13.4998 10.5254 13.4998 9.47539 12.8581 8.83372L7.4248 3.40039"
                          stroke="#1A1528"
                          strokeWidth="1.5"
                          strokeMiterlimit={10}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </a>
                </li>
                <li className="item">
                  <a href="nft-item-details.html" className="gap-12">
                    <div className="image">
                      <img src="assets/images/wallet/wallet-11.png" alt="wallet" />
                    </div>
                    <div className="content">
                      <div className="body-1">58.487037 USDT</div>
                      <p className="body-4 mt-4">$36.4212</p>
                    </div>
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.6">
                        <path
                          d="M7.4248 16.6004L12.8581 11.1671C13.4998 10.5254 13.4998 9.47539 12.8581 8.83372L7.4248 3.40039"
                          stroke="#1A1528"
                          strokeWidth="1.5"
                          strokeMiterlimit={10}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
  {/* Watchlist */}
  <div className="offcanvas offcanvas-end full" id="watchlist">
    <div className="header fixed-top">
      <div className="left">
        <a
          href="javascript:void(0);"
          data-bs-dismiss="offcanvas"
          className="icon"
        >
          <i className="icon-arrow-right" />
        </a>
      </div>
      <h3>Watchlist</h3>
      <div className="right">
        <a href="javascript:void(0);">
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
              stroke="#1A1528"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.9965 12H16.0054"
              stroke="#1A1528"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.9955 12H12.0045"
              stroke="#1A1528"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.99451 12H8.00349"
              stroke="#1A1528"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      </div>
    </div>
    <div className="overflow-auto app-content style-1">
      <div className="tf-container">
        <div className="card-box">
          <div className="gallery">
            <a href="nft-item-details.html" className="item-1 round-10 hidden">
              <img
                className="lazyload"
                data-src="assets/images/background/watchlist-1.jpg"
                alt="img"
              />
            </a>
            <a href="nft-item-details.html" className="item-2 round-10 hidden">
              <img
                className="lazyload"
                data-src="assets/images/background/watchlist-2.jpg"
                alt="img"
              />
            </a>
            <a href="nft-item-details.html" className="item-3 round-10 hidden">
              <img
                className="lazyload"
                data-src="assets/images/background/watchlist-3.jpg"
                alt="img"
              />
            </a>
          </div>
          <a href="nft-item-details.html" className="box-story">
            <div className="box-img">
              <img
                className="lazyload"
                data-src="assets/images/background/watchlist-4.jpg"
                alt="img"
              />
            </div>
            <div className="content">
              <div className="button-2">FireArt NFT</div>
              <span className="body-6">The story of FireArt NFT</span>
            </div>
          </a>
        </div>
        <div className="card-box mt-20">
          <div className="gallery">
            <a href="nft-item-details.html" className="item-1 round-10 hidden">
              <img
                className="lazyload"
                data-src="assets/images/background/watchlist-5.jpg"
                alt="img"
              />
            </a>
            <a href="nft-item-details.html" className="item-2 round-10 hidden">
              <img
                className="lazyload"
                data-src="assets/images/background/watchlist-6.jpg"
                alt="img"
              />
            </a>
            <a href="nft-item-details.html" className="item-3 round-10 hidden">
              <img
                className="lazyload"
                data-src="assets/images/background/watchlist-7.jpg"
                alt="img"
              />
            </a>
          </div>
          <a href="nft-item-details.html" className="box-story">
            <div className="box-img">
              <img
                className="lazyload"
                data-src="assets/images/background/watchlist-8.jpg"
                alt="img"
              />
            </div>
            <div className="content">
              <div className="button-2">Whos TreeHousen</div>
              <span className="body-6">The story Whos TreeHousen</span>
            </div>
          </a>
        </div>
        <div className="card-box mt-20">
          <div className="gallery">
            <a href="nft-item-details.html" className="item-1 round-10 hidden">
              <img
                className="lazyload"
                data-src="assets/images/background/watchlist-9.jpg"
                alt="img"
              />
            </a>
            <a href="nft-item-details.html" className="item-2 round-10 hidden">
              <img
                className="lazyload"
                data-src="assets/images/background/watchlist-10.jpg"
                alt="img"
              />
            </a>
            <a href="nft-item-details.html" className="item-3 round-10 hidden">
              <img
                className="lazyload"
                data-src="assets/images/background/watchlist-11.jpg"
                alt="img"
              />
            </a>
          </div>
          <a href="nft-item-details.html" className="box-story">
            <div className="box-img">
              <img
                className="lazyload"
                data-src="assets/images/background/watchlist-12.jpg"
                alt="img"
              />
            </div>
            <div className="content">
              <div className="button-2">Bad Face Bots</div>
              <span className="body-6">The story of Bad Face Bots</span>
            </div>
          </a>
        </div>
        <div className="card-box mt-20">
          <div className="gallery">
            <a href="nft-item-details.html" className="item-1 round-10 hidden">
              <img
                className="lazyload"
                data-src="assets/images/background/watchlist-13.jpg"
                alt="img"
              />
            </a>
            <a href="nft-item-details.html" className="item-2 round-10 hidden">
              <img
                className="lazyload"
                data-src="assets/images/background/watchlist-14.jpg"
                alt="img"
              />
            </a>
            <a href="nft-item-details.html" className="item-3 round-10 hidden">
              <img
                className="lazyload"
                data-src="assets/images/background/watchlist-15.jpg"
                alt="img"
              />
            </a>
          </div>
          <a href="nft-item-details.html" className="box-story">
            <div className="box-img">
              <img
                className="lazyload"
                data-src="assets/images/background/watchlist-16.jpg"
                alt="img"
              />
            </div>
            <div className="content">
              <div className="button-2">Doge Club(DOGC)</div>
              <span className="body-6">The story of Doge Club(DOGC)</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  {/* log out */}
  <div className="offcanvas offcanvas-bottom" id="logout">
    <div className="offcanvas-body">
      <h2 className="text-center text-red pb-24 mb-24 line">Logout</h2>
      <h6 className="text-center">Are you sure you want to log out?</h6>
      <div className="grid-2 gap-15 mt-32">
        <a
          href="#"
          data-bs-dismiss="offcanvas"
          className="tf-btn danger-disabled"
        >
          Cancel
        </a>
        <a onClick={logoutAction} className="tf-btn danger">
          Yes, Logout
        </a>
      </div>
    </div>
  </div>
</>

  )
}

export default More