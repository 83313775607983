import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Web3 from 'web3';
import { toast } from 'react-toastify';
import Auth from '../component/auth';

function Tasks() {

  
    return (
        <>
        <Auth/>
            <div className="header fixed-top">
                <div className="left">
                    <a href="javascript:void(0);" className="icon back-btn">
                        <i className="icon-arrow-right" />
                    </a>
                </div>
                
            </div>
            <div className="app-content">
                <div className="tf-container">
                   
                 <div class="mt-24">
                <ul class="mt-20 list-view-v7">
                    <li class="mt-20">
                        <a href="/Holdingbonus" class="item">
                            <div class="flex-grow-1 d-flex gap-16 align-items-center">
                                <div class="avatar round avt-50">
                                    <img src="assets/images/avt/piratesicon.jpeg" alt=""/>
                                </div>
                                <div class="content d-flex justify-content-between align-items-center">
                                    <div class="content-left">
                                        <p class="button-1">CARO Holding Bonus
                                        </p>
                                    </div>
                                    <div class="content-right">
                                        <span class="btn btn-success">Claim</span>
                                    </div>
                                </div>
                            </div>

                        </a>
                    </li>

                    <li class="mt-20">
                        <a href="/#" class="item">
                            <div class="flex-grow-1 d-flex gap-16 align-items-center">
                                <div class="avatar round avt-50">
                                    <img src="assets/images/avt/piratesicon.jpeg" alt=""/>
                                </div>
                                <div class="content d-flex justify-content-between align-items-center">
                                    <div class="content-left">
                                        <p class="button-1">Join Telegram
                                        </p>
                                        <span class="text-success">CINU 100000</span>
                                    </div>
                                    <div class="content-right">
                                        <span class="btn btn-warning">Soon</span>
                                    </div>
                                </div>
                            </div>

                        </a>
                    </li>

                    <li class="mt-20">
                        <a href="/#" class="item">
                            <div class="flex-grow-1 d-flex gap-16 align-items-center">
                                <div class="avatar round avt-50">
                                    <img src="assets/images/avt/piratesicon.jpeg" alt=""/>
                                </div>
                                <div class="content d-flex justify-content-between align-items-center">
                                    <div class="content-left">
                                        <p class="button-1">Subscribe Youtube
                                           
                                        </p>
                                        <span class="text-success">CINU 100000</span>
                                    </div>
                                    <div class="content-right">
                                        <span class="btn btn-warning">Soon</span>
                                    </div>
                                </div>
                            </div>

                        </a>
                    </li>

                    <li class="mt-20">
                        <a href="/#" class="item">
                            <div class="flex-grow-1 d-flex gap-16 align-items-center">
                                <div class="avatar round avt-50">
                                    <img src="assets/images/avt/piratesicon.jpeg" alt=""/>
                                </div>
                                <div class="content d-flex justify-content-between align-items-center">
                                    <div class="content-left">
                                        <p class="button-1">Follow Twitter 
                                            
                                        </p>
                                        <span class="text-success">CINU 100000</span>
                                    </div>
                                    <div class="content-right">
                                        <span class="btn btn-warning">Soon</span>
                                    </div>
                                </div>
                            </div>

                        </a>
                    </li>

                    <li class="mt-20">
                        <a href="/#" class="item">
                            <div class="flex-grow-1 d-flex gap-16 align-items-center">
                                <div class="avatar round avt-50">
                                    <img src="assets/images/avt/piratesicon.jpeg" alt=""/>
                                </div>
                                <div class="content d-flex justify-content-between align-items-center">
                                    <div class="content-left">
                                        <p class="button-1">Coin Market Cap
                                           
                                        </p>
                                        <span class="text-success">CINU 100000</span>
                                    </div>
                                    <div class="content-right">
                                        <span class="btn btn-warning">Soon</span>
                                    </div>
                                </div>
                            </div>

                        </a>
                    </li>

                    <li class="mt-20">
                        <a href="/#" class="item">
                            <div class="flex-grow-1 d-flex gap-16 align-items-center">
                                <div class="avatar round avt-50">
                                    <img src="assets/images/avt/piratesicon.jpeg" alt=""/>
                                </div>
                                <div class="content d-flex justify-content-between align-items-center">
                                    <div class="content-left">
                                        <p class="button-1">Trading View 
                                           
                                        </p>
                                        <span class="text-success">CINU 100000</span>
                                    </div>
                                    <div class="content-right">
                                        <span class="btn btn-warning">Soon</span>
                                    </div>
                                </div>
                            </div>

                        </a>
                    </li>

                    <li class="mt-20">
                        <a href="/#" class="item">
                            <div class="flex-grow-1 d-flex gap-16 align-items-center">
                                <div class="avatar round avt-50">
                                    <img src="assets/images/avt/piratesicon.jpeg" alt=""/>
                                </div>
                                <div class="content d-flex justify-content-between align-items-center">
                                    <div class="content-left">
                                        <p class="button-1">Join Facebook  
                                           
                                        </p>
                                        <span class="text-success">CINU 50000</span>
                                    </div>
                                    <div class="content-right">
                                        <span class="btn btn-warning">Soon</span>
                                    </div>
                                </div>
                            </div>

                        </a>
                    </li>

                    <li class="mt-20">
                        <a href="/#" class="item">
                            <div class="flex-grow-1 d-flex gap-16 align-items-center">
                                <div class="avatar round avt-50">
                                    <img src="assets/images/avt/piratesicon.jpeg" alt=""/>
                                </div>
                                <div class="content d-flex justify-content-between align-items-center">
                                    <div class="content-left">
                                        <p class="button-1">Join Instagram  
                                           
                                        </p>
                                        <span class="text-success">CINU 50000</span>
                                    </div>
                                    <div class="content-right">
                                        <span class="btn btn-warning">Soon</span>
                                    </div>
                                </div>
                            </div>

                        </a>
                    </li>

                    <li class="mt-20">
                        <a href="/#" class="item">
                            <div class="flex-grow-1 d-flex gap-16 align-items-center">
                                <div class="avatar round avt-50">
                                    <img src="assets/images/avt/piratesicon.jpeg" alt=""/>
                                </div>
                                <div class="content d-flex justify-content-between align-items-center">
                                    <div class="content-left">
                                        <p class="button-1">Join Coin Gecko  
                                           
                                        </p>
                                        <span class="text-success">CINU 100000</span>
                                    </div>
                                    <div class="content-right">
                                        <span class="btn btn-warning">Soon</span>
                                    </div>
                                </div>
                            </div>

                        </a>
                    </li>
                </ul>
                    </div>
                        </div>
            </div>
            <div className="menubar-footer footer-fixed">
                <ul className="inner-bar">
                    <li>
                        <a href="/">
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.02 2.84016L3.63 7.04016C2.73 7.74016 2 9.23016 2 10.3602V17.7702C2 20.0902 3.89 21.9902 6.21 21.9902H17.79C20.11 21.9902 22 20.0902 22 17.7802V10.5002C22 9.29016 21.19 7.74016 20.2 7.05016L14.02 2.72016C12.62 1.74016 10.37 1.79016 9.02 2.84016Z"
                                    stroke="#1A1528"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M12 17.9902V14.9902"
                                    stroke="#1A1528"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="/statistics">
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g opacity="0.6">
                                    <path
                                        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                        stroke="#1A1528"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M7.33008 14.4898L9.71008 11.3998C10.0501 10.9598 10.6801 10.8798 11.1201 11.2198L12.9501 12.6598C13.3901 12.9998 14.0201 12.9198 14.3601 12.4898L16.6701 9.50977"
                                        stroke="#1A1528"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </g>
                            </svg>
                        </a>
                    </li>
                    <li className="action-add-wallet">
            <a href="/wallet">
              {/* <i className="icon-plus" /> */}
              {/* <i className="bi bi-wallet2"></i> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-wallet2" viewBox="0 0 16 16">
                <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z" />
              </svg>
              {/* <img src="assets/images/wallet/wallet1.png"  alt="wallet image" /> */}

            </a>
                    </li>
                    <li className="active">
                        <a href="/profile">
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12 2C9.38 2 7.25 4.13 7.25 6.75C7.25 9.32 9.26 11.4 11.88 11.49C11.96 11.48 12.04 11.48 12.1 11.49C12.12 11.49 12.13 11.49 12.15 11.49C12.16 11.49 12.16 11.49 12.17 11.49C14.73 11.4 16.74 9.32 16.75 6.75C16.75 4.13 14.62 2 12 2Z"
                                    fill="#7F52FF"
                                />
                                <path
                                    d="M17.08 14.1509C14.29 12.2909 9.73996 12.2909 6.92996 14.1509C5.65996 15.0009 4.95996 16.1509 4.95996 17.3809C4.95996 18.6109 5.65996 19.7509 6.91996 20.5909C8.31996 21.5309 10.16 22.0009 12 22.0009C13.84 22.0009 15.68 21.5309 17.08 20.5909C18.34 19.7409 19.04 18.6009 19.04 17.3609C19.03 16.1309 18.34 14.9909 17.08 14.1509Z"
                                    fill="#7F52FF"
                                />
                            </svg>
                            <span className="text">Profile</span>
                        </a>
                    </li>
                    <li>
                        <a href="/more">
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g opacity="0.6">
                                    <path
                                        d="M5 10H7C9 10 10 9 10 7V5C10 3 9 2 7 2spanC3 2 2 3 2 5V7C2 9 3 10 5 10Z"
                                        stroke="#1A1528"
                                        strokeWidth="1.5"
                                        strokeMiterlimit={10}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M17 10H19C21 10 22 9 22 7V5C22 3 21 2 19 2H17C15 2 14 3 14 5V7C14 9 15 10 17 10Z"
                                        stroke="#1A1528"
                                        strokeWidth="1.5"
                                        strokeMiterlimit={10}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M17 22H19C21 22 22 21 22 19V17C22 15 21 14 19 14H17C15 14 14 15 14 17V19C14 21 15 22 17 22Z"
                                        stroke="#1A1528"
                                        strokeWidth="1.5"
                                        strokeMiterlimit={10}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M5 22H7C9 22 10 21 10 19V17C10 15 9 14 7 14spanC3 14 2 15 2 17V19C2 21 3 22 5 22Z"
                                        stroke="#1A1528"
                                        strokeWidth="1.5"
                                        strokeMiterlimit={10}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </g>
                            </svg>
                        </a>
                    </li>
                </ul>
                

            </div>
  
        </>

    )
}

export default Tasks