import React, { useState } from 'react';
import { FiUser, FiLock, FiEye, FiEyeOff } from 'react-icons/fi'; // Import icons from react-icons
import { toast } from 'react-toastify';

function Addsponsor() {
  const [sponsorId, setSponsorId] = useState('');
  const [messageAlert, setMessagealert] = useState(null);
  const deviceToken = localStorage.getItem('deviceToken');

  const handleLogin = async (e) => {
    e.preventDefault();
      
    console.log({ sponsorId });
    if(sponsorId == ''){
        setMessagealert('Enter Sponsor ID 1');
    }
    else
    {
        setMessagealert(null);

    try {
      const postData = {
        sponsor_id: sponsorId,
      };

      const responseRegister = await fetch('https://api.cinu.io/api/addSponsor', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${deviceToken}`,
        },
        body: JSON.stringify(postData),
      });

      if (!responseRegister.ok) {
        const errorText = await responseRegister.text();
        console.error(`HTTP error! status: ${responseRegister.status}, message: ${errorText}`);
        throw new Error(`HTTP error! status: ${responseRegister.status}`);
      }

      const resultRegister = await responseRegister.json();

      if (resultRegister.status) {
        
        console.log(resultRegister);

        toast.success(resultRegister.message);

        window.location.href = '/Profile'
      } else {
        console.log('Login failed:', resultRegister.message);
        toast.error(resultRegister.message);
      }
    } catch (error) {
      toast.error(error);
      console.error('Error during API call:', error);
      
    }
        
    }
  };

 
  return (
    <>
      <div className="header fixed-top">
        <div className="left"></div>
        <h3>ADD SPONSOR</h3>
      </div>

      <div className="app-content style-3">
        <div className="tf-container">
          <div className="mt-40 text-center banner-choose-wallet">
            <img className="lazyload" data-src="assets/images/background/bg-wallet-1.png" alt="img" />
          </div>

          <div className="mt-32">
            <h1 className="text-center">ADD SPONSOR</h1>
            <p className="mt-20 body-3 text-dark-2 text-center px-24">Add sponsor here !</p>
          </div>

          <form onSubmit={handleLogin} className="mt-32">

            {/* Email Input */}
            <div className="search-box mt-20 position-relative">
              <span className="icon-left">
                <FiUser size={20} style={{ opacity: 0.6 }} />
              </span>
              <input
                type="text"
                className="search-field"
                placeholder="Add sponsor code here"
                value={sponsorId}
                onChange={(e) => setSponsorId(e.target.value)}
              />
              <span class="text-danger">{messageAlert}</span>
            </div>

            <button type="submit" className="mt-32 tf-btn primary btn-lg">Add Sponsor</button>
          </form>

        </div>
      </div>

    </>
  );
}

export default Addsponsor;
