import React, { useEffect } from 'react';

function Auth() {
  const deviceToken = localStorage.getItem('deviceToken');

  if (!deviceToken) {
    localStorage.removeItem('deviceToken');
    sessionStorage.clear();
    window.location.href = "/login";
  }

  useEffect(() => {
    // Disable right-click

    // const handleContextMenu = (event) => {
    //   event.preventDefault();
    // };
    
    // document.addEventListener('contextmenu', handleContextMenu);

    // return () => {
    //   document.removeEventListener('contextmenu', handleContextMenu);
    // };

    // Fetch profile data if device token is present
    if (deviceToken) {
      fetchProfileData(deviceToken);
    }

  }, [deviceToken]);

  const fetchProfileData = async (token) => {
    try {
      const responseProfile = await fetch('https://api.cinu.io/api/profile', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!responseProfile.ok) {
        throw new Error(`HTTP error! status: ${responseProfile.status}`);
      }

      const resultProfile = await responseProfile.json();
      if (resultProfile.status) {
        localStorage.setItem('web3address', resultProfile.USER['wallet_address']);
        localStorage.setItem('web3key', resultProfile.USER['wallet_key']);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  return (
    <>
      {/* Your component content */}
    </>
  );
}

export default Auth;
