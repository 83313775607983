import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Web3 from 'web3';
import { toast } from 'react-toastify';
import Auth from '../../component/auth';

const Totalteam = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userid');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [stakeList, setStakeList] = useState([]);
  const [levelIncome, setLevelIncome] = useState([]);
  const deviceToken = localStorage.getItem('deviceToken');
  

  useEffect(() => {
    const fetchStakeData = async () => {
      
    try {

      const response = await fetch('https://api.cinu.io/api/stake/totalTeam', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${deviceToken}`,
        },
      });

      if (!response.ok) throw new Error('Failed to submit stake');

      const result = await response.json();
      console.log(result);

      if(result.status){
        console.log(result.levelDownline['level_1'])
        setData(result);
        setLevelIncome(result.levelDownline);

        setLoading(false);


      }
      else
      {
        toast.alert(result.message);
      }

      // Handle any additional logic or state updates here if needed
    } catch (error) {
      toast.error(error.message || "Failed to add stake");

    }
    };

    

    fetchStakeData();
  }, [userId]);
 
  const levelView =(level)=>{
      console.log('Level View ',level);
      localStorage.setItem('tealLevelView',level);
      window.location.href ="/stake-donwline-level-view";
  }



  return (
    <>
     <Auth/>
        <div className="header fixed-top">
            <div className="left">
                <a href="/stake-dashboard" className="icon back-btn">
                    <i className="icon-arrow-right"></i>
                </a>
            </div>
            <h3>MY DOWNLINE</h3>
        </div>

    {loading ?(
        <div className="">
        <div className="text-center">
          <p>Loading..</p>
        </div>    
      </div>

    ):(
        <div className="overflow-auto app-content style-1">
         


        <div class="mt-24 card">
                <div class="d-flex justify-content-between align-items-center mb-24">
                    <h4>Level History</h4>
                </div>
                <ul class="mt-20 list-view-v7">
                    <li>
                        <a  onClick={() => levelView(1)}  class="item">
                            <div class="flex-grow-1 d-flex gap-16 align-items-center">
                                <div class="avatar round avt-50">
                                    <img src="assets/images/avt/nft-logo-17.png" alt=""/>
                                </div><div class="content d-flex justify-content-between">
                                <div class="content-left">
                                    <p class="button-1">Level 1
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.3731 7.16036L13.4664 6.10703C13.2931 5.90703 13.1531 5.5337 13.1531 5.26703V4.1337C13.1531 3.42703 12.5731 2.84703 11.8664 2.84703H10.7331C10.4731 2.84703 10.0931 2.70703 9.89309 2.5337L8.83976 1.62703C8.37976 1.2337 7.62643 1.2337 7.15976 1.62703L6.11309 2.54036C5.91309 2.70703 5.53309 2.84703 5.27309 2.84703H4.11976C3.41309 2.84703 2.83309 3.42703 2.83309 4.1337V5.2737C2.83309 5.5337 2.69309 5.90703 2.52643 6.10703L1.62643 7.16703C1.23976 7.62703 1.23976 8.3737 1.62643 8.8337L2.52643 9.8937C2.69309 10.0937 2.83309 10.467 2.83309 10.727V11.867C2.83309 12.5737 3.41309 13.1537 4.11976 13.1537H5.27309C5.53309 13.1537 5.91309 13.2937 6.11309 13.467L7.16643 14.3737C7.62643 14.767 8.37976 14.767 8.84643 14.3737L9.89976 13.467C10.0998 13.2937 10.4731 13.1537 10.7398 13.1537H11.8731C12.5798 13.1537 13.1598 12.5737 13.1598 11.867V10.7337C13.1598 10.4737 13.2998 10.0937 13.4731 9.8937L14.3798 8.84036C14.7664 8.38036 14.7664 7.62036 14.3731 7.16036ZM10.7731 6.74036L7.55309 9.96036C7.45976 10.0537 7.33309 10.107 7.19976 10.107C7.06643 10.107 6.93976 10.0537 6.84643 9.96036L5.23309 8.34703C5.03976 8.1537 5.03976 7.8337 5.23309 7.64036C5.42643 7.44703 5.74643 7.44703 5.93976 7.64036L7.19976 8.90036L10.0664 6.0337C10.2598 5.84036 10.5798 5.84036 10.7731 6.0337C10.9664 6.22703 10.9664 6.54703 10.7731 6.74036Z" fill="#02bf05">
                                            </path>
                                        </svg>
                                    </p>
                                </div>
                                <div class="content-right text-end">
                                    <div class="mt-2 d-flex gap-4 align-items-center justify-content-end">
                                        <span class="button-3">Downline</span>
                                    </div>
                                    <h6 class="mt-2  text-green">{data.levelDownline['level_1']}</h6>
                                    </div>
                                </div>
                           </div>
                       </a>
                    </li>

                    <li>
                        <a  onClick={() => levelView(2)}  class="item">
                            <div class="flex-grow-1 d-flex gap-16 align-items-center">
                                <div class="avatar round avt-50">
                                    <img src="assets/images/avt/nft-logo-17.png" alt=""/>
                                </div><div class="content d-flex justify-content-between">
                                <div class="content-left">
                                    <p class="button-1">Level 2
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.3731 7.16036L13.4664 6.10703C13.2931 5.90703 13.1531 5.5337 13.1531 5.26703V4.1337C13.1531 3.42703 12.5731 2.84703 11.8664 2.84703H10.7331C10.4731 2.84703 10.0931 2.70703 9.89309 2.5337L8.83976 1.62703C8.37976 1.2337 7.62643 1.2337 7.15976 1.62703L6.11309 2.54036C5.91309 2.70703 5.53309 2.84703 5.27309 2.84703H4.11976C3.41309 2.84703 2.83309 3.42703 2.83309 4.1337V5.2737C2.83309 5.5337 2.69309 5.90703 2.52643 6.10703L1.62643 7.16703C1.23976 7.62703 1.23976 8.3737 1.62643 8.8337L2.52643 9.8937C2.69309 10.0937 2.83309 10.467 2.83309 10.727V11.867C2.83309 12.5737 3.41309 13.1537 4.11976 13.1537H5.27309C5.53309 13.1537 5.91309 13.2937 6.11309 13.467L7.16643 14.3737C7.62643 14.767 8.37976 14.767 8.84643 14.3737L9.89976 13.467C10.0998 13.2937 10.4731 13.1537 10.7398 13.1537H11.8731C12.5798 13.1537 13.1598 12.5737 13.1598 11.867V10.7337C13.1598 10.4737 13.2998 10.0937 13.4731 9.8937L14.3798 8.84036C14.7664 8.38036 14.7664 7.62036 14.3731 7.16036ZM10.7731 6.74036L7.55309 9.96036C7.45976 10.0537 7.33309 10.107 7.19976 10.107C7.06643 10.107 6.93976 10.0537 6.84643 9.96036L5.23309 8.34703C5.03976 8.1537 5.03976 7.8337 5.23309 7.64036C5.42643 7.44703 5.74643 7.44703 5.93976 7.64036L7.19976 8.90036L10.0664 6.0337C10.2598 5.84036 10.5798 5.84036 10.7731 6.0337C10.9664 6.22703 10.9664 6.54703 10.7731 6.74036Z" fill="#02bf05">
                                            </path>
                                        </svg>
                                    </p>
                                </div>
                                <div class="content-right text-end">
                                    <div class="mt-2 d-flex gap-4 align-items-center justify-content-end">
                                        <span class="button-3">Donwline</span>
                                    </div>
                                    <h6 class="mt-2  text-green">{data.levelDownline['level_2']}</h6>
                                    </div>
                                </div>
                           </div>
                       </a>
                    </li>


                    <li>
                        <a  onClick={() => levelView(3)}  class="item">
                            <div class="flex-grow-1 d-flex gap-16 align-items-center">
                                <div class="avatar round avt-50">
                                    <img src="assets/images/avt/nft-logo-17.png" alt=""/>
                                </div><div class="content d-flex justify-content-between">
                                <div class="content-left">
                                    <p class="button-1">Level 3
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.3731 7.16036L13.4664 6.10703C13.2931 5.90703 13.1531 5.5337 13.1531 5.26703V4.1337C13.1531 3.42703 12.5731 2.84703 11.8664 2.84703H10.7331C10.4731 2.84703 10.0931 2.70703 9.89309 2.5337L8.83976 1.62703C8.37976 1.2337 7.62643 1.2337 7.15976 1.62703L6.11309 2.54036C5.91309 2.70703 5.53309 2.84703 5.27309 2.84703H4.11976C3.41309 2.84703 2.83309 3.42703 2.83309 4.1337V5.2737C2.83309 5.5337 2.69309 5.90703 2.52643 6.10703L1.62643 7.16703C1.23976 7.62703 1.23976 8.3737 1.62643 8.8337L2.52643 9.8937C2.69309 10.0937 2.83309 10.467 2.83309 10.727V11.867C2.83309 12.5737 3.41309 13.1537 4.11976 13.1537H5.27309C5.53309 13.1537 5.91309 13.2937 6.11309 13.467L7.16643 14.3737C7.62643 14.767 8.37976 14.767 8.84643 14.3737L9.89976 13.467C10.0998 13.2937 10.4731 13.1537 10.7398 13.1537H11.8731C12.5798 13.1537 13.1598 12.5737 13.1598 11.867V10.7337C13.1598 10.4737 13.2998 10.0937 13.4731 9.8937L14.3798 8.84036C14.7664 8.38036 14.7664 7.62036 14.3731 7.16036ZM10.7731 6.74036L7.55309 9.96036C7.45976 10.0537 7.33309 10.107 7.19976 10.107C7.06643 10.107 6.93976 10.0537 6.84643 9.96036L5.23309 8.34703C5.03976 8.1537 5.03976 7.8337 5.23309 7.64036C5.42643 7.44703 5.74643 7.44703 5.93976 7.64036L7.19976 8.90036L10.0664 6.0337C10.2598 5.84036 10.5798 5.84036 10.7731 6.0337C10.9664 6.22703 10.9664 6.54703 10.7731 6.74036Z" fill="#02bf05">
                                            </path>
                                        </svg>
                                    </p>
                                </div>
                                <div class="content-right text-end">
                                    <div class="mt-2 d-flex gap-4 align-items-center justify-content-end">
                                        <span class="button-3">Downline</span>
                                    </div>
                                    <h6 class="mt-2  text-green">{data.levelDownline['level_3']}</h6>
                                    </div>
                                </div>
                           </div>
                       </a>
                    </li>


                    <li>
                        <a  onClick={() => levelView(4)}  class="item">
                            <div class="flex-grow-1 d-flex gap-16 align-items-center">
                                <div class="avatar round avt-50">
                                    <img src="assets/images/avt/nft-logo-17.png" alt=""/>
                                </div><div class="content d-flex justify-content-between">
                                <div class="content-left">
                                    <p class="button-1">Level 4
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.3731 7.16036L13.4664 6.10703C13.2931 5.90703 13.1531 5.5337 13.1531 5.26703V4.1337C13.1531 3.42703 12.5731 2.84703 11.8664 2.84703H10.7331C10.4731 2.84703 10.0931 2.70703 9.89309 2.5337L8.83976 1.62703C8.37976 1.2337 7.62643 1.2337 7.15976 1.62703L6.11309 2.54036C5.91309 2.70703 5.53309 2.84703 5.27309 2.84703H4.11976C3.41309 2.84703 2.83309 3.42703 2.83309 4.1337V5.2737C2.83309 5.5337 2.69309 5.90703 2.52643 6.10703L1.62643 7.16703C1.23976 7.62703 1.23976 8.3737 1.62643 8.8337L2.52643 9.8937C2.69309 10.0937 2.83309 10.467 2.83309 10.727V11.867C2.83309 12.5737 3.41309 13.1537 4.11976 13.1537H5.27309C5.53309 13.1537 5.91309 13.2937 6.11309 13.467L7.16643 14.3737C7.62643 14.767 8.37976 14.767 8.84643 14.3737L9.89976 13.467C10.0998 13.2937 10.4731 13.1537 10.7398 13.1537H11.8731C12.5798 13.1537 13.1598 12.5737 13.1598 11.867V10.7337C13.1598 10.4737 13.2998 10.0937 13.4731 9.8937L14.3798 8.84036C14.7664 8.38036 14.7664 7.62036 14.3731 7.16036ZM10.7731 6.74036L7.55309 9.96036C7.45976 10.0537 7.33309 10.107 7.19976 10.107C7.06643 10.107 6.93976 10.0537 6.84643 9.96036L5.23309 8.34703C5.03976 8.1537 5.03976 7.8337 5.23309 7.64036C5.42643 7.44703 5.74643 7.44703 5.93976 7.64036L7.19976 8.90036L10.0664 6.0337C10.2598 5.84036 10.5798 5.84036 10.7731 6.0337C10.9664 6.22703 10.9664 6.54703 10.7731 6.74036Z" fill="#02bf05">
                                            </path>
                                        </svg>
                                    </p>
                                </div>
                                <div class="content-right text-end">
                                    <div class="mt-2 d-flex gap-4 align-items-center justify-content-end">
                                        <span class="button-3">Downline</span>
                                    </div>
                                    <h6 class="mt-2  text-green">{data.levelDownline['level_4']}</h6>
                                    </div>
                                </div>
                           </div>
                       </a>
                    </li>


                    <li>
                        <a  onClick={() => levelView(5)}  class="item">
                            <div class="flex-grow-1 d-flex gap-16 align-items-center">
                                <div class="avatar round avt-50">
                                    <img src="assets/images/avt/nft-logo-17.png" alt=""/>
                                </div><div class="content d-flex justify-content-between">
                                <div class="content-left">
                                    <p class="button-1">Level 5
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.3731 7.16036L13.4664 6.10703C13.2931 5.90703 13.1531 5.5337 13.1531 5.26703V4.1337C13.1531 3.42703 12.5731 2.84703 11.8664 2.84703H10.7331C10.4731 2.84703 10.0931 2.70703 9.89309 2.5337L8.83976 1.62703C8.37976 1.2337 7.62643 1.2337 7.15976 1.62703L6.11309 2.54036C5.91309 2.70703 5.53309 2.84703 5.27309 2.84703H4.11976C3.41309 2.84703 2.83309 3.42703 2.83309 4.1337V5.2737C2.83309 5.5337 2.69309 5.90703 2.52643 6.10703L1.62643 7.16703C1.23976 7.62703 1.23976 8.3737 1.62643 8.8337L2.52643 9.8937C2.69309 10.0937 2.83309 10.467 2.83309 10.727V11.867C2.83309 12.5737 3.41309 13.1537 4.11976 13.1537H5.27309C5.53309 13.1537 5.91309 13.2937 6.11309 13.467L7.16643 14.3737C7.62643 14.767 8.37976 14.767 8.84643 14.3737L9.89976 13.467C10.0998 13.2937 10.4731 13.1537 10.7398 13.1537H11.8731C12.5798 13.1537 13.1598 12.5737 13.1598 11.867V10.7337C13.1598 10.4737 13.2998 10.0937 13.4731 9.8937L14.3798 8.84036C14.7664 8.38036 14.7664 7.62036 14.3731 7.16036ZM10.7731 6.74036L7.55309 9.96036C7.45976 10.0537 7.33309 10.107 7.19976 10.107C7.06643 10.107 6.93976 10.0537 6.84643 9.96036L5.23309 8.34703C5.03976 8.1537 5.03976 7.8337 5.23309 7.64036C5.42643 7.44703 5.74643 7.44703 5.93976 7.64036L7.19976 8.90036L10.0664 6.0337C10.2598 5.84036 10.5798 5.84036 10.7731 6.0337C10.9664 6.22703 10.9664 6.54703 10.7731 6.74036Z" fill="#02bf05">
                                            </path>
                                        </svg>
                                    </p>
                                </div>
                                <div class="content-right text-end">
                                    <div class="mt-2 d-flex gap-4 align-items-center justify-content-end">
                                        <span class="button-3">Downline</span>
                                    </div>
                                    <h6 class="mt-2  text-green">{data.levelDownline['level_5']}</h6>
                                    </div>
                                </div>
                           </div>
                       </a>
                    </li>


                    <li>
                        <a  onClick={() => levelView(6)}  class="item">
                            <div class="flex-grow-1 d-flex gap-16 align-items-center">
                                <div class="avatar round avt-50">
                                    <img src="assets/images/avt/nft-logo-17.png" alt=""/>
                                </div><div class="content d-flex justify-content-between">
                                <div class="content-left">
                                    <p class="button-1">Level 6
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.3731 7.16036L13.4664 6.10703C13.2931 5.90703 13.1531 5.5337 13.1531 5.26703V4.1337C13.1531 3.42703 12.5731 2.84703 11.8664 2.84703H10.7331C10.4731 2.84703 10.0931 2.70703 9.89309 2.5337L8.83976 1.62703C8.37976 1.2337 7.62643 1.2337 7.15976 1.62703L6.11309 2.54036C5.91309 2.70703 5.53309 2.84703 5.27309 2.84703H4.11976C3.41309 2.84703 2.83309 3.42703 2.83309 4.1337V5.2737C2.83309 5.5337 2.69309 5.90703 2.52643 6.10703L1.62643 7.16703C1.23976 7.62703 1.23976 8.3737 1.62643 8.8337L2.52643 9.8937C2.69309 10.0937 2.83309 10.467 2.83309 10.727V11.867C2.83309 12.5737 3.41309 13.1537 4.11976 13.1537H5.27309C5.53309 13.1537 5.91309 13.2937 6.11309 13.467L7.16643 14.3737C7.62643 14.767 8.37976 14.767 8.84643 14.3737L9.89976 13.467C10.0998 13.2937 10.4731 13.1537 10.7398 13.1537H11.8731C12.5798 13.1537 13.1598 12.5737 13.1598 11.867V10.7337C13.1598 10.4737 13.2998 10.0937 13.4731 9.8937L14.3798 8.84036C14.7664 8.38036 14.7664 7.62036 14.3731 7.16036ZM10.7731 6.74036L7.55309 9.96036C7.45976 10.0537 7.33309 10.107 7.19976 10.107C7.06643 10.107 6.93976 10.0537 6.84643 9.96036L5.23309 8.34703C5.03976 8.1537 5.03976 7.8337 5.23309 7.64036C5.42643 7.44703 5.74643 7.44703 5.93976 7.64036L7.19976 8.90036L10.0664 6.0337C10.2598 5.84036 10.5798 5.84036 10.7731 6.0337C10.9664 6.22703 10.9664 6.54703 10.7731 6.74036Z" fill="#02bf05">
                                            </path>
                                        </svg>
                                    </p>
                                </div>
                                <div class="content-right text-end">
                                    <div class="mt-2 d-flex gap-4 align-items-center justify-content-end">
                                        <span class="button-3">Downline</span>
                                    </div>
                                    <h6 class="mt-2  text-green">{data.levelDownline['level_6']}</h6>
                                    </div>
                                </div>
                           </div>
                       </a>
                    </li>


                    <li>
                        <a  onClick={() => levelView(7)}  class="item">
                            <div class="flex-grow-1 d-flex gap-16 align-items-center">
                                <div class="avatar round avt-50">
                                    <img src="assets/images/avt/nft-logo-17.png" alt=""/>
                                </div><div class="content d-flex justify-content-between">
                                <div class="content-left">
                                    <p class="button-1">Level 7
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.3731 7.16036L13.4664 6.10703C13.2931 5.90703 13.1531 5.5337 13.1531 5.26703V4.1337C13.1531 3.42703 12.5731 2.84703 11.8664 2.84703H10.7331C10.4731 2.84703 10.0931 2.70703 9.89309 2.5337L8.83976 1.62703C8.37976 1.2337 7.62643 1.2337 7.15976 1.62703L6.11309 2.54036C5.91309 2.70703 5.53309 2.84703 5.27309 2.84703H4.11976C3.41309 2.84703 2.83309 3.42703 2.83309 4.1337V5.2737C2.83309 5.5337 2.69309 5.90703 2.52643 6.10703L1.62643 7.16703C1.23976 7.62703 1.23976 8.3737 1.62643 8.8337L2.52643 9.8937C2.69309 10.0937 2.83309 10.467 2.83309 10.727V11.867C2.83309 12.5737 3.41309 13.1537 4.11976 13.1537H5.27309C5.53309 13.1537 5.91309 13.2937 6.11309 13.467L7.16643 14.3737C7.62643 14.767 8.37976 14.767 8.84643 14.3737L9.89976 13.467C10.0998 13.2937 10.4731 13.1537 10.7398 13.1537H11.8731C12.5798 13.1537 13.1598 12.5737 13.1598 11.867V10.7337C13.1598 10.4737 13.2998 10.0937 13.4731 9.8937L14.3798 8.84036C14.7664 8.38036 14.7664 7.62036 14.3731 7.16036ZM10.7731 6.74036L7.55309 9.96036C7.45976 10.0537 7.33309 10.107 7.19976 10.107C7.06643 10.107 6.93976 10.0537 6.84643 9.96036L5.23309 8.34703C5.03976 8.1537 5.03976 7.8337 5.23309 7.64036C5.42643 7.44703 5.74643 7.44703 5.93976 7.64036L7.19976 8.90036L10.0664 6.0337C10.2598 5.84036 10.5798 5.84036 10.7731 6.0337C10.9664 6.22703 10.9664 6.54703 10.7731 6.74036Z" fill="#02bf05">
                                            </path>
                                        </svg>
                                    </p>
                                </div>
                                <div class="content-right text-end">
                                    <div class="mt-2 d-flex gap-4 align-items-center justify-content-end">
                                        <span class="button-3">Downline</span>
                                    </div>
                                    <h6 class="mt-2  text-green">{data.levelDownline['level_7']}</h6>
                                    </div>
                                </div>
                           </div>
                       </a>
                    </li>
                </ul>
                

                
            </div>


        </div>    
    )}


    </>
  )
}

export default Totalteam