import React, { useState, useEffect } from 'react';
import { FiUser, FiMail, FiPhone, FiLock, FiEye, FiEyeOff, FiTag } from 'react-icons/fi'; // Import icons from react-icons
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

function Register() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userid');
  const inviteId = queryParams.get('invite');
  const [data, setData] = useState(null);

  


  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const postData = {
        name: name,
        email: email,
        mobile: mobile,
        referralCode: referralCode,
        password: password,
      };

      const responseRegister = await fetch('https://api.cinu.io/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      if (!responseRegister.ok) {
        const errorText = await responseRegister.text();
        throw new Error(`HTTP error! status: ${responseRegister.status}`);
      }

      const resultRegister = await responseRegister.json();

      if (resultRegister.status) {
        const deviceToken = resultRegister.token;
        if (deviceToken) {
          localStorage.setItem('deviceToken', deviceToken);

          toast.success('Congratulations, you’re on board! Welcome to CARO Games—where gaming meets real rewards. Start playing and earning instantly! Let the games begin!');
      setTimeout(() => {
        window.location.href = '/login';
      }, 5000);


        }
        
      } else {
        
        toast.error(resultRegister.message);
      }
    } catch (error) {
      
      toast.error(error);
    }
   
  };


  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };



  useEffect(() => {
    if (userId) {
      registerWithGame(userId);
    }
    else{
      
    }

    if(inviteId){
      setReferralCode(inviteId);
    }
  }, [userId]);

  const registerWithGame = async (userId) =>{
    try {
      const responseOne = await fetch(`${process.env.REACT_APP_API_URL}?userid=${userId}&accessKey=${process.env.REACT_APP_ACCESS_KEY}`);
      if (!responseOne.ok) {
        throw new Error(`HTTP error! status: ${responseOne.status}`);
      }
      const resultOne = await responseOne.json();
      setData(resultOne);
      
      if (resultOne.status) {
        getLoginRegister(
          resultOne.userid,
          resultOne.userName,
          resultOne.userEmail,
          resultOne.userMobile,
          resultOne.userSponsor,
          resultOne.userPassword
        );
      } else {
        
      }
    } catch (error) {
      
    }
  }


  const getLoginRegister = async (userUserID, userName, userEmail, userMobile, userSponsor, userPassword) => {
    try {
      const postData = {
        user_id: userUserID,
        name: userName,
        email: userEmail,
        mobile: userMobile,
        sponsor_id: userSponsor,
        password: userPassword,
      };

      const responseTwo = await fetch('https://api.cinu.io/api/loginWithGame', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      if (!responseTwo.ok) {
        const errorText = await responseTwo.text();
        
        throw new Error(`HTTP error! status: ${responseTwo.status}`);
      }

      const resultTwo = await responseTwo.json();

      if (resultTwo.status) {
        const deviceToken = resultTwo.token;
        if (deviceToken) {
          localStorage.setItem('deviceToken', deviceToken);
          window.location.href = '/';
        } else {

        }

      } else {
      }
    } catch (error) {
      
    }
  }


  return (
    <>
      <div className="header fixed-top">
        <div className="left"></div>
        <h3>REGISTER</h3>
      </div>

      <div className="app-content style-3">
        <div className="tf-container">
          <div className="mt-40 text-center banner-choose-wallet">
            <img className="lazyload" data-src="assets/images/background/bg-wallet-1.png" alt="img" />
          </div>

          <div className="mt-32">
            <h1 className="text-center">Register Here</h1>
            <p className="mt-20 body-3 text-dark-2 text-center px-24">Connect With Caro Vault Wallet!</p>
          </div>

          <form onSubmit={handleRegister} className="mt-32">

            {/* Name Input */}
            <div className="search-box mt-20 position-relative">
              <span className="icon-left">
                <FiUser size={20} style={{ opacity: 0.6 }} />
              </span>
              <input
                type="text"
                className="search-field"
                placeholder="Enter Name Here"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            {/* Email Input */}
            <div className="search-box mt-20 position-relative">
              <span className="icon-left">
                <FiMail size={20} style={{ opacity: 0.6 }} />
              </span>
              <input
                type="email"
                className="search-field"
                placeholder="Enter Email Here"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            {/* Mobile Input */}
            <div className="search-box mt-20 position-relative">
              <span className="icon-left">
                <FiPhone size={20} style={{ opacity: 0.6 }} />
              </span>
              <input
                type="text"
                className="search-field"
                placeholder="Enter Mobile Number"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>

            {/* Referral Code Input */}
            <div className="search-box mt-20 position-relative">
              <span className="icon-left">
                <FiTag size={20} style={{ opacity: 0.6 }} />
              </span>
              <input
                type="text"
                className="search-field"
                placeholder="Enter Referral Code"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
              />
            </div>

            {/* Password Input with show/hide functionality */}
            <div className="search-box mt-20 position-relative">
              <span className="icon-left">
                <FiLock size={20} style={{ opacity: 0.6 }} />
              </span>
              <input
                type={showPassword ? 'text' : 'password'}
                className="search-field"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* Toggle show/hide password icon */}
              <span
                className="password-toggle-icon"
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                }}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <FiEyeOff size={20} style={{ opacity: 0.6 }} />
                ) : (
                  <FiEye size={20} style={{ opacity: 0.6 }} />
                )}
              </span>
            </div>

            <button type="submit" className="mt-32 tf-btn primary btn-lg">Register</button>
          </form>

          <div className="mt-24 d-flex justify-content-center gap-6">
            <p className="body-3">I have an account!</p>
            <a href="login" className="button-2 text-primary">Login</a>
          </div>
        </div>
      </div>

    </>
  );
}

export default Register;
