import React, { useEffect, useState } from 'react';
import Auth from '../component/auth';

function Directincome() {
  const deviceToken = localStorage.getItem('deviceToken');
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const tokenName = localStorage.getItem('tokenName');

  useEffect(() => {
    // Fetch transaction data from the API
    const fetchTransactions = async () => {
      try {
        const response = await fetch('https://api.cinu.io/api/directIncome', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${deviceToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data.');
        }

        const dataslot = await response.json();
        setTransactions(dataslot.data); // Set slot data from API response
      } catch (error) {
        setError('Failed to fetch data.');
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [deviceToken]);

  return (
    <>
      <Auth />
      <div className="header fixed-top">
        <div className="left">
          <a href="/Wallet" className="icon back-btn">
            <i className="icon-arrow-right" />
          </a>
        </div>
        <h3>Direct Income</h3>
      </div>
      <div className="app-content">
        <div className="tab-content mt-24">
          <div className="tab-pane fade active show" id="ranking" role="tabpanel">
            <div className="px-16 mt-24">
              {loading ? (
                <p>Loading transactions...</p>
              ) : error ? (
                <p>{error}</p>
              ) : (
                <ul className="mt-24 list-view-v6 check-list">
                  {transactions.map((transaction, index) => (
                    <li key={transaction.id || index} className="mt-20">
                      <a href="javascript:void(0);" className="item box-accordion gap-16">
                        <div
                          className="box d-flex justify-content-between align-items-center gap-16 collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target={`#accordion-${index}`}
                          aria-expanded="true"
                          aria-controls={`accordion-${index}`}
                        >
                          <div className="flex-grow-1 d-flex gap-16 align-items-center">
                            <div className="avatar round avt-48">
                              <img src="assets/images/avt/inIcon.png" alt="" />
                            </div>
                            <div className="content d-flex justify-content-between">
                              <div className="content-left">
                                <p className="button-1">{transaction.sponsor_name}</p>
                                <span className="more text-primary mt-2 button-3">
                                  Reward: $ {transaction.reward} 
                                </span>
                                <span className="less text-primary mt-2 button-3">
                                  Reward: $ {transaction.reward} 
                                </span>
                              </div>
                              <div className="content-right text-end">
                                <div className="mt-2 d-flex gap-4 align-items-center justify-content-end">
                                <span className="button-2 text-danger">
                                     {transaction.slot_name}
                                </span>
                                </div>
                                <span className="mt-2 d-block body-4 text-dark-2">
                                  $ {transaction.amount}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id={`accordion-${index}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`accordion-${index}`}
                        >
                          <ul className="body pt-16 line-t d-flex gap-40 justify-content-between">
                            <li>
                              <div className="body-5">Reward</div>
                              <span className="button-3">{transaction.percentage} %</span>
                            </li>
                            <li>
                              <div className="body-5">Date Time</div>
                              <span className="button-3">{transaction.date_time}</span>
                            </li>
                          </ul>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Directincome;
