import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import { toast } from 'react-toastify';
import Auth from '../component/auth';

function Myslotsdetails() {
  const deviceToken = localStorage.getItem('deviceToken');
  const slotId = localStorage.getItem('slotId');

  const [table_id, setTableId] = useState(null);
  const [slotSlot_id, setSlotSlotId] = useState(null);
  const [slotName, setSlotName] = useState(null);
  const [slotAmount, setSlotAmount] = useState(null);
  const [slotUsdtAmount, setSlotUsdtAmount] = useState(null);
  const [slotUsdtStatus, setSlotUsdtStatus] = useState(null);
  const [slotCaroAmount, setSlotCaroAmount] = useState(null);
  const [slotCaroStatus, setSlotCaroStatus] = useState(null);
  const [slotDateTime, setSlotRecycle] = useState(null);
  const [slotTeam, setSlotTeam] = useState(null);
  const [slotDirectIncome, setSlotDirectIncome] = useState(null);
  const [slotAutopoolIncome, setSlotAutopoolIncome] = useState(null);
  const [slotRecycleIncome, setSlotRecycleIncome] = useState(null);
  const [slotTotalIncome, setSlottotalIncome] = useState(null);




  useEffect(() => {

    // setUsdtAmount('Loading..');
    // setCaroAmount('Loading..');

    fetchProfileData(deviceToken);

    
  }, []);

  const fetchProfileData = (token) => {
    fetch('https://api.cinu.io/api/mySlots', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Uncomment if the API requires authorization
        'Authorization': `Bearer ${deviceToken}`,
      },
    })
      .then(response => response.json())
      .then(dataslot => {
        const slots = dataslot.data; // Assume dataslot.data is an array
  
        // Filter the slots where the id matches the slotId
        const filteredSlots = slots.filter(slot => slot.id == slotId);
  
        // Check if we have any matching slots
        if (filteredSlots.length > 0) {
          // Assuming you want the first matching slot
          const currentSlot = filteredSlots[0];
          setTableId(currentSlot.id);
          setSlotSlotId(currentSlot.slot_id);
          setSlotName(currentSlot.slot_name);
          setSlotAmount(currentSlot.amount);
          setSlotUsdtAmount(currentSlot.usdt_amt);
          setSlotUsdtStatus(currentSlot.usdt_status);
          setSlotCaroAmount(currentSlot.caro_amt);
          setSlotCaroStatus(currentSlot.caro_status);
          setSlotRecycle(currentSlot.recycle);
          setSlotTeam(currentSlot.team);
          setSlotDirectIncome(currentSlot.dierct_income);
          setSlotAutopoolIncome(currentSlot.auto_pool_income);
          setSlotRecycleIncome(currentSlot.recycle_amout);
          setSlottotalIncome(currentSlot.total_income);
          


        } else {
        }
        
        // Optionally set the state or perform other actions with filteredSlots
        // setMySlotData(filteredSlots);
        
        // Log or perform actions based on the filtered data
      })
      .catch(error => {
        // Handle errors appropriately, e.g., setError('Failed to fetch data.');
        // setIsLoading(false);
      });
  };
  
  const payCaro =() =>{


    const  slotCaroAmt = slotAmount-slotUsdtAmount;

    localStorage.setItem('slotId', slotSlot_id);
    localStorage.setItem('slotName', slotName);
    localStorage.setItem('slotAmount', slotAmount);
    localStorage.setItem('slotUsdtAmt', slotUsdtAmount);
    localStorage.setItem('slotCaroAmt', slotCaroAmt);

    window.location.href = '/Slotjoin';
  }


  const getTeamDetails = () =>{

    localStorage.setItem('teamDetailsId', table_id);
    localStorage.setItem('teamDetailsSlotId', slotSlot_id);

    window.location.href = '/Teamdetails';
  }



  return (
    <>
    <Auth/>
    <div className="header fixed-top">
    <div className="left">
        <a href="/" className="icon back-btn">
        <i className="icon-arrow-right" />
        </a>
    </div>
    <h3>Slots Details</h3>
    </div>
    <div class="app-content style-2">
        <div class="px-24 card-layout style-2">
            <div class="card-nft-1 mt-24">
                <div class="card-header" data-bs-toggle="collapse" data-bs-target="#details-accordion" aria-expanded="true" aria-controls="details-accordion">
                    <h6>Details</h6>  
                </div>
                <div id="details-accordion" class="accordion-collapse collapse show" aria-labelledby="details-accordion">
                    <div class="card-body">
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">SLOT NAME</span>
                            <span class="button-2 text-primary">{slotName}</span>
                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">SLOT AMOUNT</span>
                            <span class="button-2 text-primary">$ {slotAmount}</span>
                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">USDT AMOUNT</span>
                            <span class="button-2">{slotUsdtAmount} USDT</span>
                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">USDT STATUS</span>
                            <span class="button-2">{slotUsdtStatus}</span>
                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">CARO AMOUNT</span>
                            <span className="button-2"> {slotCaroAmount}  CARO</span>

                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">CARO STATUS</span>
                            <span className="button-2">{slotCaroStatus}</span>

                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">RECYCLE STATUS </span>
                            <span class="button-2 text-success">{slotDateTime}</span>
                        </div>
                        <a onClick={getTeamDetails}>
                        <div class=" mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">TEAM </span>
                            <span class="button-2 text-success"> <b>{slotTeam}</b></span>
                        </div>
                        </a>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">DIRCET INCOME </span>
                            <span class="button-2 text-success">$ {slotDirectIncome}</span>
                        </div>

                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">AUTO POOL INCOME </span>
                            <span class="button-2 text-success">$ {slotAutopoolIncome}</span>
                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">RECYCLE INCOME</span>
                            <span class="button-2 text-success">$ {slotRecycleIncome}</span>
                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">TOTAL INCOME</span>
                            <span class="button-2 text-success">$ {slotTotalIncome}</span>
                        </div>
                    </div>
                </div>       
            </div>
        </div>
        
    </div>

      <div className="btn-fixed">
              {slotCaroStatus === 'pending' && (
              <a onClick={() => payCaro(slotId)} className="tf-btn primary">PAY CARO</a>
              ) }

      </div>

    
    </>
  );
}

export default Myslotsdetails;
