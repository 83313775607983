import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import { toast } from 'react-toastify';
import Auth from '../component/auth';

function Teamdetails() {
  const deviceToken = localStorage.getItem('deviceToken');
  const tableId = localStorage.getItem('teamDetailsId');
  const teamSlotID =  localStorage.getItem('teamDetailsSlotId');
  const [leftUserNAme, setLeftUserName] = useState(null);
  const [middelUserName, setMiddelUserName] = useState(null);
  const [rightUserName, setRightUserName] = useState(null);
  const [leftOneName, setLeftOneName] = useState(null);
  const [lefTwoName, setLeftTwoName] = useState(null);
  const [leftThreeName, setLeftThreeName] = useState(null);

  const [middelOneName, setMiddelOneName] = useState(null);
  const [middelTwoName, setMiddelTwoName] = useState(null);
  const [middelThreeName, setMiddelThreeName] = useState(null);

  const [rightOneName, setRightOneName] = useState(null);
  const [rightTwoName, setRightTwoName] = useState(null);
  const [rightThreeName, setRightThreeName] = useState(null);




  useEffect(() => {

    // setUsdtAmount('Loading..');
    // setCaroAmount('Loading..');

    fetchProfileData(deviceToken);

    
  }, []);

  const fetchProfileData = async (token) => {

    try {
        const postData = {
        slot_id: teamSlotID,
        id: tableId,
        };
    
        const responseAddSlot = await fetch('https://api.cinu.io/api/joinLegDetails', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${deviceToken}`, 
          },
          body: JSON.stringify(postData),
        });
    
        if (!responseAddSlot.ok) {
          const errorText = await responseAddSlot.text();
          throw new Error(`HTTP error! status: ${responseAddSlot.status}`);
        }
    
        const resultAddSlot = await responseAddSlot.json();
    
        if (resultAddSlot.status) {
    
          setLeftUserName(resultAddSlot.leftUser);
          setMiddelUserName(resultAddSlot.middelUser);
          setRightUserName(resultAddSlot.rightUser);
          setLeftOneName(resultAddSlot.leftOneName);
          setLeftTwoName(resultAddSlot.leftTwoName);
          setLeftThreeName(resultAddSlot.leftThreeName);
          setMiddelOneName(resultAddSlot.middelOneName);
          setMiddelTwoName(resultAddSlot.middelTwoName);
          setMiddelThreeName(resultAddSlot.middelThreeName);

          setRightOneName(resultAddSlot.rightOneName);
          setRightTwoName(resultAddSlot.rightTwoName);
          setRightThreeName(resultAddSlot.rightThreeName);
          
          
        } else {
    
    
    
        }
      } catch (error) {
      }
    
  };
  



  return (
    <>
    <Auth/>
    <div className="header fixed-top">
    <div className="left">
        <a href="/" className="icon back-btn">
        <i className="icon-arrow-right" />
        </a>
    </div>
    <h3>Team Details</h3>
    </div>
    <div class="app-content style-2">
        <div class="px-24 card-layout style-2">
            <div class="card-nft-1 mt-24">
                <div class="card-header" data-bs-toggle="collapse" data-bs-target="#details-accordion" aria-expanded="true" aria-controls="details-accordion">
                    <h6>Details</h6>  
                </div>
                <div id="details-accordion" class="accordion-collapse collapse show" aria-labelledby="details-accordion">
                    <div class="card-body">
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2"><b class="text-danger">1</b> LEFT</span>
                            <span class="button-2 text-primary">{leftUserNAme ?? 'No User'}</span>
                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2"><b class="text-danger">2</b> MIDDEL</span>
                            <span class="button-2 text-primary"> {middelUserName ?? 'No User'}</span>
                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2"><b class="text-danger">3</b> RIGHT</span>
                            <span class="button-2 text-primary">{rightUserName ?? 'No User'}</span>
                        </div>

                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2"><b class="text-danger">4</b> LEFT LEFT</span>
                            <span class="button-2 text-primary">{leftOneName ?? 'No User'}</span>
                        </div>

                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2"><b class="text-danger">5</b> LEFT MIDDEL</span>
                            <span class="button-2 text-primary">{lefTwoName ?? 'No User'}</span>
                        </div>

                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2"><b class="text-danger">6</b> LEFT RIGHT</span>
                            <span class="button-2 text-primary">{leftThreeName ?? 'No User'}</span>
                        </div>

                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2"><b class="text-danger">7</b> MIDDEL LEFT</span>
                            <span class="button-2 text-primary">{middelOneName ?? 'No User'}</span>
                        </div>

                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2"><b class="text-danger">8</b> MIDDEL MIDDEL</span>
                            <span class="button-2 text-primary">{middelTwoName ?? 'No User'}</span>
                        </div>

                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2"><b class="text-danger">9</b> MIDDEL RIGHT</span>
                            <span class="button-2 text-primary">{middelThreeName ?? 'No User'}</span>
                        </div>

                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2"><b class="text-danger">10</b> RIGHT LEFT</span>
                            <span class="button-2 text-primary">{rightOneName ?? 'No User'}</span>
                        </div>

                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2"><b class="text-danger">11</b> RIGHT MIDDEL</span>
                            <span class="button-2 text-primary">{rightTwoName ?? 'No User'}</span>
                        </div>

                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2"><b class="text-danger">12</b> RIGHT RIGHT</span>
                            <span class="button-2 text-primary">{rightThreeName ?? 'No User'}</span>
                        </div>
                    </div>
                </div>       
            </div>
        </div>
        
    </div>


    
    </>
  );
}

export default Teamdetails;
