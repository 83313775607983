import React, { useState, useRef, useEffect } from 'react';

const UserLocationWithCamera = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);
  const videoRef = useRef(null);

  // Get user's location
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  };

  // Access camera
  const startCamera = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        setError('Error accessing the camera: ' + err.message);
      }
    } else {
      setError('Camera access is not supported by this browser.');
    }
  };

  useEffect(() => {
    startCamera(); // Start the camera as soon as the component is mounted
  }, []);

  return (
    <div>
      <h2>User Location and Camera</h2>

      {/* Get Location Button */}
      <button onClick={getLocation}>Get Location</button>
      {location.latitude && location.longitude ? (
        <div>
          <p>Latitude: {location.latitude}</p>
          <p>Longitude: {location.longitude}</p>
        </div>
      ) : (
        <p>{error || 'Click the button to get location.'}</p>
      )}

      {/* Display Camera Feed */}
      <div>
        <h3>Camera Feed</h3>
        <video ref={videoRef} autoPlay style={{ width: '100%', height: 'auto' }} />
      </div>
    </div>
  );
};

export default UserLocationWithCamera;
