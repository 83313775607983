import React, { useEffect, useState } from 'react';
import Auth from '../component/auth';



function Home() {
  const [slotData, setSlotData] = useState([]);
  const [mySlotData, setMySlotData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const deviceToken = localStorage.getItem('deviceToken');
  

  useEffect( async () => {
    const deviceToken = localStorage.getItem('deviceToken');
      await fetchRunningSlots(deviceToken);
      await fetchSlots();

  }, []);

  const fetchRunningSlots = async (deviceToken)=>{
    // Fetch data from the API
    fetch('https://api.cinu.io/api/mySlots', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Uncomment if the API requires authorization
          'Authorization': `Bearer ${deviceToken}`,
      },
    })
      .then(response => response.json())
      .then(dataslot => {
        setMySlotData(dataslot.data); // Set slot data from API response
       // setIsLoading(false);
      })
      .catch(error => {
        setError('Failed to fetch data.');
        setIsLoading(false);
      });
  }

  const viewMySlots = (mySlotID) =>{

    localStorage.setItem('slotId', mySlotID);

    window.location.href = '/Myslotsdetails';
  }


  const fetchSlots = async ()=>{
    // Fetch data from the API
    fetch('https://api.cinu.io/api/slot/data', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Uncomment if the API requires authorization
        // 'Authorization': `Bearer ${deviceToken}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setSlotData(data.data); // Set slot data from API response
        setIsLoading(false);
      })
      .catch(error => {
        setError('Failed to fetch data.');
        setIsLoading(false);
      });
  } 

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return <div>{error}</div>;
  // }

  const joinSlots = (slotId,slotName, slotAmount, slotUsdtAmt, slotCaroAmt) => {
    // Implement your joinSlots function here

    localStorage.setItem('slotId', slotId);
    localStorage.setItem('slotName', slotName);
    localStorage.setItem('slotAmount', slotAmount);
    localStorage.setItem('slotUsdtAmt', slotUsdtAmt);
    localStorage.setItem('slotCaroAmt', slotCaroAmt);

    window.location.href = '/Slotjoin';
  };

  
  return (
    <>
     <Auth/>
      <div className="header-style2 fixed-top">
        <div className="logo">
          <h2><b className="text-primary">C3 CLUB</b></h2>
        </div>
      </div>
      <div className="app-content">
        <div className="tf-container">
        <div className="pb-24 mb-24 line">
            <h4 className="mt-24">Become a C3 Community Partner!</h4>
            <p className="body-3 mt-12 text-dark-2">
            Start contributing and enjoy exclusive benefits, all just one step away!
            </p>
          </div>

          <div className="pb-24 mb-24 line">
            <h4 className="mt-24">Join Our Stake program!</h4>
            <p className="body-3 mt-12 text-dark-2">
            Get Unlimited Benefits on every Stake 
            </p>
            <div className="text-end">
            <a href="/stake-dashboard" class="btn btn-primary btn-sm">View Details</a>
            </div>
            
          </div>

          <div className="mt-24 card-banner-1 p-20 round-16" style={{ backgroundImage: "url(assets/images/banner-1.jpg)" }}>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
          <div className="mt-24">
            <div className="d-flex justify-content-between align-items-center">
              <h4>Running Slots</h4>
              <a href="/Myslots" className="text-primary fw-6">
                See All
              </a>
            </div>
            <ul class="mt-20 list-view-v7">

                {mySlotData.length > 0 ? (
                    mySlotData.slice(0, 4).map((mySlot) => (
                      <li key={mySlot.id} onClick={() => viewMySlots(mySlot.id)} >
                            <a href="#" class="item">
                                <div class="flex-grow-1 d-flex gap-16 align-items-center">
                                    <div class="avatar round avt-50">
                                        <img src="assets/images/avt/nft-logo-17.png" alt=""/>
                                    </div>
                                    <div class="content d-flex justify-content-between">
                                        <div class="content-left">
                                            <p class="button-1">{mySlot.slot_name}
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    {mySlot.caro_status === 'success' ? (
                                                         <path
                                                         d="M14.3731 7.16036L13.4664 6.10703C13.2931 5.90703 13.1531 5.5337 13.1531 5.26703V4.1337C13.1531 3.42703 12.5731 2.84703 11.8664 2.84703H10.7331C10.4731 2.84703 10.0931 2.70703 9.89309 2.5337L8.83976 1.62703C8.37976 1.2337 7.62643 1.2337 7.15976 1.62703L6.11309 2.54036C5.91309 2.70703 5.53309 2.84703 5.27309 2.84703H4.11976C3.41309 2.84703 2.83309 3.42703 2.83309 4.1337V5.2737C2.83309 5.5337 2.69309 5.90703 2.52643 6.10703L1.62643 7.16703C1.23976 7.62703 1.23976 8.3737 1.62643 8.8337L2.52643 9.8937C2.69309 10.0937 2.83309 10.467 2.83309 10.727V11.867C2.83309 12.5737 3.41309 13.1537 4.11976 13.1537H5.27309C5.53309 13.1537 5.91309 13.2937 6.11309 13.467L7.16643 14.3737C7.62643 14.767 8.37976 14.767 8.84643 14.3737L9.89976 13.467C10.0998 13.2937 10.4731 13.1537 10.7398 13.1537H11.8731C12.5798 13.1537 13.1598 12.5737 13.1598 11.867V10.7337C13.1598 10.4737 13.2998 10.0937 13.4731 9.8937L14.3798 8.84036C14.7664 8.38036 14.7664 7.62036 14.3731 7.16036ZM10.7731 6.74036L7.55309 9.96036C7.45976 10.0537 7.33309 10.107 7.19976 10.107C7.06643 10.107 6.93976 10.0537 6.84643 9.96036L5.23309 8.34703C5.03976 8.1537 5.03976 7.8337 5.23309 7.64036C5.42643 7.44703 5.74643 7.44703 5.93976 7.64036L7.19976 8.90036L10.0664 6.0337C10.2598 5.84036 10.5798 5.84036 10.7731 6.0337C10.9664 6.22703 10.9664 6.54703 10.7731 6.74036Z"
                                                         fill="#02bf05" />
                                                    ) : (
                                                      <path
                                                         d="M14.3731 7.16036L13.4664 6.10703C13.2931 5.90703 13.1531 5.5337 13.1531 5.26703V4.1337C13.1531 3.42703 12.5731 2.84703 11.8664 2.84703H10.7331C10.4731 2.84703 10.0931 2.70703 9.89309 2.5337L8.83976 1.62703C8.37976 1.2337 7.62643 1.2337 7.15976 1.62703L6.11309 2.54036C5.91309 2.70703 5.53309 2.84703 5.27309 2.84703H4.11976C3.41309 2.84703 2.83309 3.42703 2.83309 4.1337V5.2737C2.83309 5.5337 2.69309 5.90703 2.52643 6.10703L1.62643 7.16703C1.23976 7.62703 1.23976 8.3737 1.62643 8.8337L2.52643 9.8937C2.69309 10.0937 2.83309 10.467 2.83309 10.727V11.867C2.83309 12.5737 3.41309 13.1537 4.11976 13.1537H5.27309C5.53309 13.1537 5.91309 13.2937 6.11309 13.467L7.16643 14.3737C7.62643 14.767 8.37976 14.767 8.84643 14.3737L9.89976 13.467C10.0998 13.2937 10.4731 13.1537 10.7398 13.1537H11.8731C12.5798 13.1537 13.1598 12.5737 13.1598 11.867V10.7337C13.1598 10.4737 13.2998 10.0937 13.4731 9.8937L14.3798 8.84036C14.7664 8.38036 14.7664 7.62036 14.3731 7.16036ZM10.7731 6.74036L7.55309 9.96036C7.45976 10.0537 7.33309 10.107 7.19976 10.107C7.06643 10.107 6.93976 10.0537 6.84643 9.96036L5.23309 8.34703C5.03976 8.1537 5.03976 7.8337 5.23309 7.64036C5.42643 7.44703 5.74643 7.44703 5.93976 7.64036L7.19976 8.90036L10.0664 6.0337C10.2598 5.84036 10.5798 5.84036 10.7731 6.0337C10.9664 6.22703 10.9664 6.54703 10.7731 6.74036Z"
                                                         fill="#bf0202" />
                                                    )}  
                                                                                                     
                                                </svg>
                                            </p>
                                            <div class="mt-2 d-flex align-items-center">
                                                <span class="body-5 text-dark-2">Floor Price : </span>
                                                <span class="body-3 d-flex align-items-center">
                                                    
                                                    ${mySlot.amount}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="content-right text-end">
                                            <div class="mt-2 d-flex gap-4 align-items-center justify-content-end">
                                               
                                                <span class="button-3">TEAM : {mySlot.team}</span>
                                            </div>
                                            <span class="mt-2 d-block body-3 text-green">+ ${mySlot.total_income}</span>
                                        </div>
                                    </div>
                                </div>

                            </a>
                        </li>
                    ))
                  ) : (
                    <p>No data available.</p>
                  )}
            </ul>
          </div>
          <div className="mt-24">
            <div className="d-flex justify-content-between align-items-center">
              <h4>Join SLOT</h4>
              <a href="/AllSlot" className="text-primary fw-6">
                See All
              </a>
            </div>
            
            <div className="grid-2 mt-24 gap-15">
            {slotData.length > 0 ? (
                slotData.slice(0, 4).map((slot) => ( // Only show the first 4 items
                  <a
                    key={slot.id}
                    onClick={() => joinSlots(slot.id,slot.slot_name, slot.slot_amount, slot.slot_usdt_amt, slot.slot_caro_amt)}
                    className="card-nft"
                  >
                    <div className="box-img">
                      <img
                        className="lazyload"
                        src={slot.slot_image || 'assets/images/nfts/nft-9.jpg'} // Fallback image
                        alt={slot.slot_name || 'Slot Image'}
                      />
                    </div>
                    <div className="content">
                      <div className="button-1 name">
                        {slot.slot_name || 'Unknown Slot'} <b className="text-danger">$: {slot.slot_amount}</b>
                      </div>
                      <p className="mt-4 id-name">
                        USDT: {slot.slot_usdt_amt}, CARO: {slot.slot_caro_amt}
                        <svg
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.3731 7.16036L13.4664 6.10703C13.2931 5.90703 13.1531 5.5337 13.1531 5.26703V4.1337C13.1531 3.42703 12.5731 2.84703 11.8664 2.84703H10.7331C10.4731 2.84703 10.0931 2.70703 9.89309 2.5337L8.83976 1.62703C8.37976 1.2337 7.62643 1.2337 7.15976 1.62703L6.11309 2.54036C5.91309 2.70703 5.53309 2.84703 5.27309 2.84703H4.11976C3.41309 2.84703 2.83309 3.42703 2.83309 4.1337V5.2737C2.83309 5.5337 2.69309 5.90703 2.52643 6.10703L1.62643 7.16703C1.23976 7.62703 1.23976 8.3737 1.62643 8.8337L2.52643 9.8937C2.69309 10.0937 2.83309 10.467 2.83309 10.727V11.867C2.83309 12.5737 3.41309 13.1537 4.11976 13.1537H5.27309C5.53309 13.1537 5.91309 13.2937 6.11309 13.467L7.16643 14.3737C7.62643 14.767 8.37976 14.767 8.84643 14.3737L9.89976 13.467C10.0998 13.2937 10.4731 13.1537 10.7398 13.1537H11.8731C12.5798 13.1537 13.1598 12.5737 13.1598 11.867V10.7337C13.1598 10.4737 13.2998 10.0937 13.4731 9.8937L14.3798 8.84036C14.7664 8.38036 14.7664 7.62036 14.3731 7.16036ZM10.7731 6.74036L7.55309 9.96036C7.45976 10.0537 7.33309 10.107 7.19976 10.107C7.06643 10.107 6.93976 10.0537 6.84643 9.96036L5.23309 8.34703C5.03976 8.1537 5.03976 7.8337 5.23309 7.64036C5.42643 7.44703 5.74643 7.44703 5.93976 7.64036L7.19976 8.90036L10.0664 6.0337C10.2598 5.84036 10.5798 5.84036 10.7731 6.0337C10.9664 6.22703 10.9664 6.54703 10.7731 6.74036Z"
                            fill="#2664ED"
                          />
                        </svg>
                      </p>
                    </div>
                  </a>
                ))
              ) : (
                <p>No data available.</p>
              )}

            </div>
          </div>
         
        </div>
      </div>
      <div className="menubar-footer footer-fixed">
        <ul className="inner-bar">
          <li className="active">
            <a href="/">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.0402 6.81969L14.2802 2.78969C12.7102 1.68969 10.3002 1.74969 8.79023 2.91969L3.78023 6.82969C2.78023 7.60969 1.99023 9.20969 1.99023 10.4697V17.3697C1.99023 19.9197 4.06023 21.9997 6.61023 21.9997H17.3902C19.9402 21.9997 22.0102 19.9297 22.0102 17.3797V10.5997C22.0102 9.24969 21.1402 7.58969 20.0402 6.81969ZM12.7502 17.9997C12.7502 18.4097 12.4102 18.7497 12.0002 18.7497C11.5902 18.7497 11.2502 18.4097 11.2502 17.9997V14.9997C11.2502 14.5897 11.5902 14.2497 12.0002 14.2497C12.4102 14.2497 12.7502 14.5897 12.7502 14.9997V17.9997Z"
                  fill="#7F52FF"
                />
              </svg>
            </a>
          </li>
          <li>
            <a href="/Statistics">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.6">
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="#1A1528"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.33008 14.4898L9.71008 11.3998C10.0501 10.9598 10.6801 10.8798 11.1201 11.2198L12.9501 12.6598C13.3901 12.9998 14.0201 12.9198 14.3601 12.4898L16.6701 9.50977"
                    stroke="#1A1528"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </a>
          </li>
          <li className="action-add-wallet">
            <a href="/wallet">
              {/* <i className="icon-plus" /> */}
              {/* <i className="bi bi-wallet2"></i> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-wallet2" viewBox="0 0 16 16">
                <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z" />
              </svg>
              {/* <img src="assets/images/wallet/wallet1.png"  alt="wallet image" /> */}

            </a>
          </li>
          <li>
            <a href="/profile">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.6">
                  <path
                    d="M12.1596 10.87C12.0596 10.86 11.9396 10.86 11.8296 10.87C9.44957 10.79 7.55957 8.84 7.55957 6.44C7.55957 3.99 9.53957 2 11.9996 2C14.4496 2 16.4396 3.99 16.4396 6.44C16.4296 8.84 14.5396 10.79 12.1596 10.87Z"
                    stroke="#1A1528"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.15973 14.56C4.73973 16.18 4.73973 18.82 7.15973 20.43C9.90973 22.27 14.4197 22.27 17.1697 20.43C19.5897 18.81 19.5897 16.17 17.1697 14.56C14.4297 12.73 9.91973 12.73 7.15973 14.56Z"
                    stroke="#1A1528"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </a>
          </li>
          <li>
            <a href="/more">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.6">
                  <path
                    d="M5 10H7C9 10 10 9 10 7V5C10 3 9 2 7 2H5C3 2 2 3 2 5V7C2 9 3 10 5 10Z"
                    stroke="#1A1528"
                    strokeWidth="1.5"
                    strokeMiterlimit={10}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17 10H19C21 10 22 9 22 7V5C22 3 21 2 19 2H17C15 2 14 3 14 5V7C14 9 15 10 17 10Z"
                    stroke="#1A1528"
                    strokeWidth="1.5"
                    strokeMiterlimit={10}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17 22H19C21 22 22 21 22 19V17C22 15 21 14 19 14H17C15 14 14 15 14 17V19C14 21 15 22 17 22Z"
                    stroke="#1A1528"
                    strokeWidth="1.5"
                    strokeMiterlimit={10}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5 22H7C9 22 10 21 10 19V17C10 15 9 14 7 14H5C3 14 2 15 2 17V19C2 21 3 22 5 22Z"
                    stroke="#1A1528"
                    strokeWidth="1.5"
                    strokeMiterlimit={10}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </a>
          </li>
        </ul>
      </div>
      
    </>
  )
}

export default Home