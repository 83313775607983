import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Web3 from 'web3';
import { toast } from 'react-toastify';
import Auth from '../../component/auth';

const Stake = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userid');
  const web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/'));
  
  const [stakeData, setStakeData] = useState(null);
  const [caroLivePrice, setCaroLivePrice] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [enteredAmount, setEnteredAmount] = useState(0);
  const [bonusPercentage, setBonusPercentage] = useState('');
  const deviceToken = localStorage.getItem('deviceToken');
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const fetchStakeData = async () => {
      try {
        const response = await fetch('https://api.cinu.io/api/stakeSetting', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        if (!response.ok) throw new Error('Failed to fetch data');
        const data = await response.json();
        setStakeData(data.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const getLivePrice = async () => {
      try {
        const responsePrice = await fetch('https://dream2earn.com/app/api/livePrice.php', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        if (!responsePrice.ok) throw new Error(`HTTP error! Status: ${responsePrice.status}`);
        const result = await responsePrice.json();
        if (result) setCaroLivePrice(result.CARO);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchStakeData();
    getLivePrice();
  }, [userId]);

  const handleAmountChange = (e) => {
    const amount = parseFloat(e.target.value);
    setEnteredAmount(amount);

    if (stakeData) {
      const matchingStake = stakeData.find((item) => amount >= item.fromAmt && amount <= item.twoAmt);
      setBonusPercentage(matchingStake ? matchingStake.percentage : '');
    }
  };

  const stakeStart = async () => {

    if(!enteredAmount){
        toast.error('Enter Amount')
        return;
    }

    try {

        setShowLoader(true);
      const response = await fetch('https://api.cinu.io/api/stake/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${deviceToken}`,
        },
        body: JSON.stringify({
          amount: enteredAmount,
          caroPrice: caroLivePrice,
        }),
      });

      if (!response.ok) throw new Error('Failed to submit stake');

      const result = await response.json();
      

      if(result.status){
        toast.success("Stake added successfully!");
      console.log(result.data)
      localStorage.setItem('stakeId',result.data['id']);

      window.location.href ="/stake-complete"

      }
      else
      {
        toast.alert(result.message);
      }

      // Handle any additional logic or state updates here if needed
    } catch (error) {
      toast.error(error.message || "Failed to add stake");

      setShowLoader(false);
    }
  };

  return (
    <>
      <Auth />
      <div className="app-content style-2">
        <div className="header fixed-top">
          <div className="left">
            <a href="/stake-dashboard" className="icon back-btn">
              <i className="icon-arrow-right"></i>
            </a>
          </div>
          <h3>STAKE</h3>
        </div>

        <div className="style-2">
          {loading ? (
            <div className="tf-container">
              <div className="text-center">
                <p>Loading..</p>
              </div>    
            </div>
          ) : error ? (
            <p className="text-danger">{error}</p>
          ) : (
            <div className="tf-container">
              <h6>Enter Stake Amount</h6>
              <div className="mt-10">
                <input
                  type="number"
                  id="assetAmount"
                  name="assetAmount"
                  className="form-control"
                  onChange={handleAmountChange}
                />
                <span className="text-danger">{"amountAlert"}</span>
              </div>

              <h6 className="mt-20">Stake for Days</h6>
              <div className="mt-10">
                <input type="number" id="stakeDays" value="730" name="stakeDays" className="form-control" readOnly />
              </div>

              <h6 className="mt-20">Total Staking Reward %</h6>
              <div className="mt-10">
                <input
                  type="text"
                  id="bonusPercentage"
                  name="bonusPercentage"
                  className="form-control"
                  readOnly
                  value={bonusPercentage}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="btn-fixed">
        <div className="gap-15">
          


              {showLoader ? (
                <div class="text-center" >
              <img src="assets/images/loadingIcon.gif" alt="Loading" style={{ width: '50%' }}/>
              </div>
              ):(
                <a onClick={stakeStart} className="tf-btn primary btn-icon"> <span className=""></span>STAKE NOW</a>
              )}
        </div>
      </div>
    </>
  );
};

export default Stake;
