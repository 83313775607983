// App.js
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Rewards from './Pages/Rewards';
import Statistics from './Pages/Statistics';
import Profile from './Pages/Profile';
import More from './Pages/More';
import Setting from './Pages/Setting';
import Create from './Pages/Create';
import Wallet from './Pages/Wallet';
import Receive from './Pages/Receive';
import Send from './Pages/Send';
import Web3txn from './Pages/Web3txn';
import AllSlot from './Pages/AllSlot';
import Slotjoin from './Pages/Slotjoin';
import Register from './Pages/register';
import Login from './Pages/Login';
import Myslots from './Pages/Myslots';
import Addsponsor from './Pages/Addsponsor'
import Myslotsdetails from './Pages/Myslotsdetails';
import Directincome from './Pages/Directincome';
import Withdraw from './Pages/Withdraw';
import Referral from './Pages/Referral';
import Teamdetails from './Pages/Teamdetails';
import Tasks from './Pages/Tasks';
import Holdingbonus from './Pages/Holdingbonus';
import Farmcinu from './Pages/Farmcinu';
import Resetpassword from './Pages/Resetpassword';
import Location from './Pages/Location';
import Deposit from './Pages/Deposit';
import GameDeposit from './Pages/GameDeposit';

import Dashboard from './Pages/Stake/Dashboard';
import Stake from './Pages/Stake/Stake';
import Stakecomplete from './Pages/Stake/Stakecomplete';
import Mining from './Pages/Stake/Mining';
import Spotincome from './Pages/Stake/Spotincome';
import Spotlevelview from './Pages/Stake/Spotlevelview';
import Levelincome from './Pages/Stake/Levelincome';
import Stakelevelincome from './Pages/Stake/Stakelevelincome';
import Stakewithdraw from './Pages/Stake/Stakewithdraw';
import Directreferral from './Pages/DirectReferral';
import Totalteam from './Pages/Stake/Totalteam';
import Teamlevelview from './Pages/Stake/Teamlevelview';






function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/rewards" element={<Rewards />} />
        <Route path="/statistics" element={<Statistics />} />
        <Route path="/profile" element={<Profile />} />
        {/* <Route path="/qr-code" element={<Qrcode />} /> */}
        <Route path="/more" element={<More />} />
        <Route path="/create" element={<Create />} />
        <Route path="/receive" element={<Receive />} />
        {/* <Route path="/login" element={<Login />} /> */}
        {/* <Route path="/register" element={<Register />} /> */}
        {/* <Route path="/verifyaccount" element={<Verifyaccount />} /> */}
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/send" element={<Send />} />
        <Route path="/web3txn" element={<Web3txn />} />
        <Route path="/allslot" element={<AllSlot />} />
        <Route path="/Slotjoin" element={<Slotjoin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Myslots" element={<Myslots />} />
        <Route path="/Addsponsor" element={<Addsponsor />} />
        <Route path="/Myslotsdetails" element={<Myslotsdetails/>}/>
        <Route path="/Directincome" element={<Directincome/>}/>
        <Route path="/Withdraw" element={<Withdraw/>}/>
        <Route path="/Referral" element={<Referral/>}/>
        <Route path="/Teamdetails" element={<Teamdetails/>}/>
        <Route path="/Tasks" element={<Tasks/>}/>
        <Route path="/Holdingbonus" element={<Holdingbonus/>}/>
        <Route path="/Farmcinu" element={<Farmcinu/>}/>
        <Route path="/Resetpassword" element={<Resetpassword/>}/>
        <Route path="/Location" element={<Location/>}/>
        <Route path="/Deposit" element={<Deposit/>}/>
        <Route path="/GameDeposit" element={<GameDeposit/>}/>
        <Route path="/stake-dashboard" element={<Dashboard/>}/>
        <Route path="/stake-stake" element={<Stake/>}/>
        <Route path="/stake-complete" element={<Stakecomplete/>}/>
        <Route path="/stake-mining" element={<Mining/>}/>
        <Route path="/stake-spot-income" element={<Spotincome/>}/>
        <Route path="/stake-spot-level-view" element={<Spotlevelview/>}/>
        <Route path="/stake-level-income" element={<Levelincome/>}/>
        <Route path="/stake-level-income-view" element={<Stakelevelincome/>}/>
        <Route path="/stake-withdraw" element={<Stakewithdraw/>}/>
        <Route path="/stake-direct-referral" element={<Directreferral/>}/>
        <Route path="/stake-total-team" element={<Totalteam/>}/>
        <Route path="/stake-donwline-level-view" element={<Teamlevelview/>}/>
        

        

        



        






        
        
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
