import React from 'react'
import Auth from '../component/auth';

function Create() {
  return (
    <>
    <Auth/>
      <div className="header fixed-top">
        <div className="left">
          <a href="/" className="icon back-btn fs-12">
            <i className="icon-close2" />
          </a>
        </div>
        <h3>Create New Item</h3>
        <div className="right">
          <a href="index.html">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.02 2.84016L3.63 7.04016C2.73 7.74016 2 9.23016 2 10.3602V17.7702C2 20.0902 3.89 21.9902 6.21 21.9902H17.79C20.11 21.9902 22 20.0902 22 17.7802V10.5002C22 9.29016 21.19 7.74016 20.2 7.05016L14.02 2.72016C12.62 1.74016 10.37 1.79016 9.02 2.84016Z"
                stroke="#1A1528"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 17.9902V14.9902"
                stroke="#1A1528"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </a>
        </div>
      </div>
      <div className="app-content style-2">
        <div className="tf-container">
          <h4 className="text-dark-3">Image, Video, Audio, or 3D Model</h4>
          <p className="mt-12 body-3 text-dark-2">
            File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB,
            GLTF Max size: 100 MB
          </p>
          <div className="card-banner-2 mt-20">
            <img
              className="lazyload"
              data-src="assets/images/banner/banner-nft-4.jpg"
              alt="img"
            />
            <div className="box-top d-flex align-items-center justify-content-end">
              <div className="box-icon w-40 box-heart">
                <span className="icon icon-heart" />
              </div>
            </div>
          </div>
          <div className="mt-24">
            <h4 className="text-dark-3">Name</h4>
            <input
              type="text"
              className="form-control mt-16"
              defaultValue="Beaconikla #284"
            />
          </div>
          <div className="mt-24">
            <h4 className="text-dark-3">External link (Optional)</h4>
            <p className="mt-12 text-dark-2 body-3">
              ENFTI will include a link to this URL on this item detail page, so
              that users can click to learn more about it. You’re welcome to link to
              your own webpage with more details.
            </p>
            <input
              type="text"
              className="mt-16 form-control"
              defaultValue="https://opensea.io/collection/nekochimin"
            />
          </div>
          <div className="mt-24">
            <h4 className="text-dark-3">Description (Optional)</h4>
            <p className="mt-12 text-dark-2 body-3">
              The description will be included on the item's detail page underneath
              its image. Markdown syntax is supported.
            </p>
            <textarea
              className="form-control mt-16"
              defaultValue={
                "Beaconikla is a collection of random NFT (Non - Fungible Token) generated and resides on the Ethereum Blockchain. We focus on making NFTs that are unique and rare."
              }
            />
          </div>
          <div className="mt-24 pb-24 mb-24 line-3">
            <h4 className="text-dark-3">Collection</h4>
            <p className="mt-12 text-dark-2 body-3">
              This is the collection where your item will appear.
            </p>
            <div className="mt-16 nice-select tf-select-v3" tabIndex={0}>
              <span className="current">Beacon</span>
              <svg
                className="arrow"
                width={20}
                height={20}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6 7.45898L11.1667 12.8923C10.525 13.534 9.47503 13.534 8.83336 12.8923L3.40002 7.45898"
                  stroke="#1A1528"
                  strokeWidth="1.5"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <ul className="list">
                <li data-value={1} className="option selected">
                  Beacon
                </li>
                <li data-value={2} className="option selected">
                  Beacon
                </li>
                <li data-value={3} className="option selected">
                  Beacon
                </li>
              </ul>
            </div>
          </div>
          <div className="mb-24 pb-24 line-3">
            <div className="card pb-20">
              <div className="card-header d-flex gap-12">
                <div className="icon icon-document-text text-gradient-1 fs-40" />
                <div className="flex-grow-1">
                  <h6 className="text-dark-3">Properties</h6>
                  <p className="mt-6 body-5 text-dark-2">
                    Textual traits that show up as rectangles
                  </p>
                </div>
              </div>
              <div className="card-body">
                <div className="delete-item pt-20 mt-20 line-t2">
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="body-4">BACKGROUND</span>
                    <span className="icon-close2 text-dark fs-12 btn-del" />
                  </div>
                  <input
                    type="text"
                    className="mt-12 form-control"
                    defaultValue="Bannana Dark Violet MIxed"
                  />
                </div>
                <div className="delete-item mt-20">
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="body-4">BODY</span>
                    <span className="icon-close2 text-dark fs-12 btn-del" />
                  </div>
                  <input
                    type="text"
                    className="mt-12 form-control"
                    defaultValue="Blue"
                  />
                </div>
                <div className="delete-item mt-20">
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="body-4">CLOTHES</span>
                    <span className="icon-close2 text-dark fs-12 btn-del" />
                  </div>
                  <input
                    type="text"
                    className="mt-12 form-control"
                    defaultValue="Full T-Shirt"
                  />
                </div>
                <div className="delete-item mt-20">
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="body-4">FACE</span>
                    <span className="icon-close2 text-dark fs-12 btn-del" />
                  </div>
                  <input
                    type="text"
                    className="mt-12 form-control"
                    defaultValue="Brown"
                  />
                </div>
                <div className="delete-item mt-20">
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="body-4">HAT</span>
                    <span className="icon-close2 text-dark fs-12 btn-del" />
                  </div>
                  <input
                    type="text"
                    className="mt-12 form-control"
                    defaultValue="Cream Cap"
                  />
                </div>
                <div className="delete-item mt-20">
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="body-4">HEADPHONE</span>
                    <span className="icon-close2 text-dark fs-12 btn-del" />
                  </div>
                  <input
                    type="text"
                    className="mt-12 form-control"
                    defaultValue="White"
                  />
                </div>
                <div className="delete-item mt-20">
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="body-4">HAIR</span>
                    <span className="icon-close2 text-dark fs-12 btn-del" />
                  </div>
                  <input
                    type="text"
                    className="mt-12 form-control"
                    defaultValue="Violet"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header d-flex gap-12">
              <div className="icon icon-star text-gradient-2 fs-40" />
              <div className="flex-grow-1">
                <h6 className="text-dark-3">Levels</h6>
                <p className="mt-6 body-5 text-dark-2">
                  Numerical traits that show as a progress bar
                </p>
              </div>
            </div>
            <div className="mt-20 card-body">
              <a href="#" className="btn-add bg-rgba-orange">
                <span className="icon icon-plus text-orange" />
              </a>
            </div>
          </div>
          <div className="card mt-24">
            <div className="card-header d-flex gap-12">
              <div className="icon icon-activity text-gradient-3 fs-40" />
              <div className="flex-grow-1">
                <h6 className="text-dark-3">Stats</h6>
                <p className="mt-6 body-5 text-dark-2">
                  Numerical traits that show as a progress bar
                </p>
              </div>
            </div>
            <div className="mt-20 card-body">
              <a href="#" className="btn-add bg-rgba-blue">
                <span className="icon icon-plus text-secondary-1" />
              </a>
            </div>
          </div>
          <div className="card mt-24">
            <div className="d-flex justify-content-between gap-12">
              <div className="icon icon-unlock text-gradient-4 fs-40" />
              <div className="content flex-grow-1">
                <div className="d-flex justify-content-between">
                  <label htmlFor="sw1" className="text-dark-3 flex-grow-1 h6">
                    Unlockable Content
                  </label>
                  <input type="checkbox" className="tf-switch-check" id="sw1" />
                </div>
                <p className="mt-6 body-5 text-dark-2">
                  Include unlockable content that can only be revealed by the owner
                  of the item.
                </p>
              </div>
            </div>
          </div>
          <div className="card mt-24">
            <div className="d-flex justify-content-between gap-12">
              <div className="icon icon-info-circle text-gradient-5 fs-40" />
              <div className="content flex-grow-1">
                <div className="d-flex justify-content-between">
                  <label htmlFor="sw2" className="text-dark-3 flex-grow-1 h6">
                    Explicit &amp; Sensitive Content
                  </label>
                  <input type="checkbox" className="tf-switch-check" id="sw2" />
                </div>
                <p className="mt-6 body-5 text-dark-2">
                  Set this item as explicit and sensitive content
                </p>
              </div>
            </div>
          </div>
          <div className="mt-24">
            <h4 className="text-dark-3">Supply</h4>
            <p className="mt-12 text-dark-2 body-3">
              The number of items that can be minted. No gas cost to you!
            </p>
            <input type="text" className="mt-16 form-control" defaultValue={1} />
          </div>
          <div className="mt-24">
            <h4 className="text-dark-3">Blockchain</h4>
            <div className="mt-16 nice-select tf-select-v3 icon" tabIndex={0}>
              <span className="current">Ethereum</span>
              <svg
                className="icon icon-eth"
                width={20}
                height={20}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.74998 7.58294L9.72494 5.81628C9.89994 5.74128 10.1 5.74128 10.2666 5.81628L14.2416 7.58294C14.5916 7.74128 14.9166 7.31628 14.6749 7.01628L10.5083 1.92461C10.2249 1.57461 9.75829 1.57461 9.47495 1.92461L5.30829 7.01628C5.07495 7.31628 5.39998 7.74128 5.74998 7.58294Z"
                  fill="#1A1528"
                />
                <path
                  d="M5.75006 12.4171L9.73336 14.1837C9.90836 14.2587 10.1084 14.2587 10.2751 14.1837L14.2584 12.4171C14.6084 12.2587 14.9334 12.6837 14.6917 12.9837L10.525 18.0754C10.2417 18.4254 9.77505 18.4254 9.49171 18.0754L5.32505 12.9837C5.07505 12.6837 5.39172 12.2587 5.75006 12.4171Z"
                  fill="#1A1528"
                />
                <path
                  d="M9.81672 7.90898L6.37505 9.62565C6.06672 9.77565 6.06672 10.2173 6.37505 10.3673L9.81672 12.084C9.93339 12.1423 10.075 12.1423 10.1917 12.084L13.6333 10.3673C13.9417 10.2173 13.9417 9.77565 13.6333 9.62565L10.1917 7.90898C10.0667 7.85065 9.93339 7.85065 9.81672 7.90898Z"
                  fill="#1A1528"
                />
              </svg>
              <svg
                className="arrow"
                width={20}
                height={20}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6 7.45898L11.1667 12.8923C10.525 13.534 9.47503 13.534 8.83336 12.8923L3.40002 7.45898"
                  stroke="#1A1528"
                  strokeWidth="1.5"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <ul className="list">
                <li data-value="last-month" className="option selected">
                  Ethereum
                </li>
                <li data-value="last-week" className="option">
                  Polygon
                </li>
                <li data-value="last-week" className="option">
                  Klaytn
                </li>
                <li data-value="last-week" className="option">
                  Solana
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-24">
            <p className="mt-12 body-3 text-dark-2">
              Freezing your metadata will allow you to permanently lock and store
              all of this item's content in decentralized file storage.
              <br />
              To freeze your metadata, you must create your item’s first.
            </p>
          </div>
        </div>
      </div>
      <div className="btn-fixed">
        <div className="grid-2 gap-15">
          <a href="/" className="tf-btn disabled-primary">
            CANCEL
          </a>
          <a href="/" className="tf-btn primary">
            CREATE
          </a>
        </div>
      </div>
    </>

  )
}

export default Create