import React, { useEffect, useState } from 'react';
import Auth from '../../component/auth';
import { toast } from 'react-toastify';

function Stakelevelincome() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const level = localStorage.getItem('stakeLevel');
  const deviceToken = localStorage.getItem('deviceToken');

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch('https://api.cinu.io/api/stake/level/income', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${deviceToken}`,
          },
          body: JSON.stringify({ level }),
        });

        if (!response.ok) throw new Error('Failed to submit stake');
        const result = await response.json();

        if (result.status) {
          setTransactions(result.levelIncome); // Assuming `result.data` contains transaction data

          console.log(result);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        setError(error.message || "Failed to add stake");
        toast.error(error.message || "Failed to add stake");
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [deviceToken, level]);

  return (
    <>
      <Auth />
      <div className="header fixed-top">
        <div className="left">
          <a href="/stake-level-income" className="icon back-btn">
            <i className="icon-arrow-right" />
          </a>
        </div>
        <h3>Level {level}</h3>
      </div>
      <div className="app-content">
     <div className="tab-content mt-24">
       <div className="tab-pane fade active show" id="ranking" role="tabpanel">
         <div className="px-16 mt-24">
            {loading ? (
                <div class="text-center" >
                <img src="assets/images/loadingIcon.gif" alt="Loading" style={{ width: '50%' }}/>
                </div>
              ) : error ? (
                <p>{error}</p>
              ) : (
                <ul className="mt-24 list-view-v6 check-list">
                  {transactions.map((transaction, index) => (
                    <li key={transaction.hash} className="mt-20">
                      <a  href="javascript:void(0);"  className="item box-accordion gap-16">
                        <div
                          className="box d-flex justify-content-between align-items-center gap-16 collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target={`#accordion-${index}`}
                          aria-expanded="true"
                          aria-controls={`accordion-${index}`}
                        >
                          <div className="flex-grow-1 d-flex gap-16 align-items-center">
                            <div className="avatar round avt-48">
                              
                                    <img src="assets/images/avt/inIcon.png" alt="" />
                               
                            </div>
                            <div className="content d-flex justify-content-between">
                              <div className="content-left">
                                <p className="button-1">{transaction.name} </p>
                                
                                    <span className="more text-primary mt-2 button-3">
                                         {transaction.percentage}% 
                                   </span>
                               

                                

                                    <span className="less text-primary mt-2 button-3">
                                    {transaction.percentage}% 
                                  </span>
                                


                                

                              </div>
                              <div className="content-right text-end">
                                <div className="mt-2 d-flex gap-4 align-items-center justify-content-end">
                                  
                                  <span className="button-2 text-success">
                                    ${transaction.stakeAmt}
                                  </span>
                                </div>
                                <span className="mt-2 d-block body-4 text-dark-2">
                                  Reward : ${transaction.reward}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id={`accordion-${index}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`accordion-${index}`}
                        >
                          <ul className="body pt-16 line-t d-flex gap-40 justify-content-between">
                            <li>
                              <div className="body-5">Status</div>
                              <span className="button-3">{transaction.status}</span>
                            </li>
                            <li>
                              <div className="body-5">Date Time</div>
                              <span className="button-3">{transaction.dateTime}</span>
                            </li>
                          </ul>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              )}
         </div>
       </div>
       
     </div>
   </div>

      
    </>
  );
}

export default Stakelevelincome;
