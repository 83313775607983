import React, { useEffect, useState } from 'react';
import Auth from '../component/auth';

function AllSlot() {
  const [slotData, setSlotData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const deviceToken = localStorage.getItem('deviceToken');


  useEffect(() => {
    // Fetch data from the API
    fetch('https://api.cinu.io/api/slot/data', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Uncomment if the API requires authorization
        // 'Authorization': `Bearer ${deviceToken}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setSlotData(data.data); // Set slot data from API response
        setIsLoading(false);
      })
      .catch(error => {
        setError('Failed to fetch data.');
        setIsLoading(false);
      });
  }, []);

  const joinSlots = (slotId,slotName, slotAmount, slotUsdtAmt, slotCaroAmt) => {
    // Implement your joinSlots function here

    localStorage.setItem('slotId', slotId);
    localStorage.setItem('slotName', slotName);
    localStorage.setItem('slotAmount', slotAmount);
    localStorage.setItem('slotUsdtAmt', slotUsdtAmt);
    localStorage.setItem('slotCaroAmt', slotCaroAmt);

    window.location.href = '/Slotjoin';
  };

  return (
    <>
    <Auth/>
      <div className="header fixed-top">
        <div className="left">
          <a href="/" className="icon back-btn">
            <i className="icon-arrow-right" />
          </a>
        </div>
        <h3>ALL Slots</h3>
      </div>
      <div className="app-content">
        <div className="tf-container">
          <div className="mt-24">
            <div className="d-flex justify-content-between align-items-center">
              <h4>Current Slots</h4>
            </div>
            <div className="grid-2 mt-24 gap-15">
              {slotData.length > 0 ? (
                slotData.map((slot) => ( // Display all slot data
                  <a
                    key={slot.id}
                    onClick={() => joinSlots(slot.id,slot.slot_name, slot.slot_amount, slot.slot_usdt_amt, slot.slot_caro_amt)}
                    className="card-nft"
                  >
                    <div className="box-img">
                      <img
                        className="lazyload"
                        src={slot.slot_image || 'assets/images/nfts/nft-9.jpg'} // Fallback image
                        alt={slot.slot_name || 'Slot Image'}
                      />
                    </div>
                    <div className="content">
                      <div className="button-1 name">
                        {slot.slot_name || 'Unknown Slot'} <b className="text-danger">$: {slot.slot_amount}</b>
                      </div>
                      <p className="mt-4 id-name">
                        USDT: {slot.slot_usdt_amt}, CARO: {slot.slot_caro_amt}
                        <svg
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.3731 7.16036L13.4664 6.10703C13.2931 5.90703 13.1531 5.5337 13.1531 5.26703V4.1337C13.1531 3.42703 12.5731 2.84703 11.8664 2.84703H10.7331C10.4731 2.84703 10.0931 2.70703 9.89309 2.5337L8.83976 1.62703C8.37976 1.2337 7.62643 1.2337 7.15976 1.62703L6.11309 2.54036C5.91309 2.70703 5.53309 2.84703 5.27309 2.84703H4.11976C3.41309 2.84703 2.83309 3.42703 2.83309 4.1337V5.2737C2.83309 5.5337 2.69309 5.90703 2.52643 6.10703L1.62643 7.16703C1.23976 7.62703 1.23976 8.3737 1.62643 8.8337L2.52643 9.8937C2.69309 10.0937 2.83309 10.467 2.83309 10.727V11.867C2.83309 12.5737 3.41309 13.1537 4.11976 13.1537H5.27309C5.53309 13.1537 5.91309 13.2937 6.11309 13.467L7.16643 14.3737C7.62643 14.767 8.37976 14.767 8.84643 14.3737L9.89976 13.467C10.0998 13.2937 10.4731 13.1537 10.7398 13.1537H11.8731C12.5798 13.1537 13.1598 12.5737 13.1598 11.867V10.7337C13.1598 10.4737 13.2998 10.0937 13.4731 9.8937L14.3798 8.84036C14.7664 8.38036 14.7664 7.62036 14.3731 7.16036ZM10.7731 6.74036L7.55309 9.96036C7.45976 10.0537 7.33309 10.107 7.19976 10.107C7.06643 10.107 6.93976 10.0537 6.84643 9.96036L5.23309 8.34703C5.03976 8.1537 5.03976 7.8337 5.23309 7.64036C5.42643 7.44703 5.74643 7.44703 5.93976 7.64036L7.19976 8.90036L10.0664 6.0337C10.2598 5.84036 10.5798 5.84036 10.7731 6.0337C10.9664 6.22703 10.9664 6.54703 10.7731 6.74036Z"
                            fill="#2664ED"
                          />
                        </svg>
                      </p>
                    </div>
                  </a>
                ))
              ) : (
                <p>No data available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllSlot;
