import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Web3 from 'web3';
import { toast } from 'react-toastify';
import Auth from '../../component/auth';

const Dashboard = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userid');
  const [loading, setLoading] = useState(false);
  const [myStake, setMyStake] = useState([]);
  const [stakeList, setStakeList] = useState([]);
  const deviceToken = localStorage.getItem('deviceToken');
  const web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/')); // Binance Smart Chain endpoint
  

  useEffect(() => {
    const fetchStakeData = async () => {
      
    try {

      const response = await fetch('https://api.cinu.io/api/stake/history', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${deviceToken}`,
        },
      });


      const result = await response.json();
      

      if(result.status){
        console.log(result)
        setMyStake(result.data);
        setStakeList(result.stakeList);

        setLoading(false);


      }
      else
      {
      //  toast.alert(result.message);
      }

      // Handle any additional logic or state updates here if needed
    } catch (error) {
      toast.error(error.message || "Failed to add stake");

    }
    };

    

    fetchStakeData();
  }, [userId]);
 

  const minetStake =(stakeId)=>{
    console.log('Mint Id',stakeId);

    localStorage.setItem('stakeId',stakeId);

      window.location.href ="/stake-mining"
    
  }

  const viewStake =(stakeId)=>{
    console.log('View Id',stakeId);
    localStorage.setItem('stakeId',stakeId);

      window.location.href ="/stake-complete"
  }

  return (
    <>
     <Auth/>
        <div className="header fixed-top">
            <div className="left">
                <a href="/" className="icon back-btn">
                    <i className="icon-arrow-right"></i>
                </a>
            </div>
            <h3>STAKE</h3>
        </div>

        <div className="overflow-auto app-content style-1">
         <div className="card-nft-1 mt-24">
            <div className="card-header" data-bs-toggle="collapse" data-bs-target="#properties" aria-expanded="true" aria-controls="properties">
                <h6>My Stakes</h6>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.9201 8.94922L13.4001 15.4692C12.6301 16.2392 11.3701 16.2392 10.6001 15.4692L4.08008 8.94922" stroke="#1A1528" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>    
            </div>
            <div id="properties" className="accordion-collapse collapse show" aria-labelledby="properties">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center pb-12 mb-12 line-2">
                        <div className="content-left">
                            <h1 className="text-primary button-2">My STake</h1>
                        </div>
                        <h6 className="text-primary">${myStake.totalStake || '0'}</h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pb-12 mb-12 line-2">
                        <div className="content-left">
                            <h1 className="text-primary button-2">Total Return</h1>
                        </div>
                        <h6 className="text-primary">${myStake.totalReturn || '0'}</h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pb-12 mb-12 line-2">
                        <div className="content-left">
                            <h1 className="text-primary button-2">Credited</h1>
                        </div>
                        <h6 className="text-primary">${myStake.totalCredit || '0'}</h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pb-12 mb-12 line-2">
                        <div className="content-left">
                            <h1 className="text-primary button-2">Remain</h1>
                        </div>
                        <h6 className="text-primary">${myStake.totalRemain || '0'}</h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="content-left">
                            <h1 className="text-primary button-2">Capping</h1>
                        </div>
                        <h6 className="text-primary">${myStake.totalCapping || '0'}</h6>
                    </div>
                </div>
            </div>       
        </div>

        <div className="card-nft-1 mt-24">
            <div className="card-header" data-bs-toggle="collapse" data-bs-target="#incomes" aria-expanded="true" aria-controls="properties">
                <h6>Incomes</h6>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.9201 8.94922L13.4001 15.4692C12.6301 16.2392 11.3701 16.2392 10.6001 15.4692L4.08008 8.94922" stroke="#1A1528" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>    
            </div>
            <div id="incomes" className="accordion-collapse collapse show" aria-labelledby="incomes">
                <div className="card-body">
                    <a href="/stake-spot-income">
                    <div className="d-flex justify-content-between align-items-center pb-12 mb-12 line-2">
                        <div className="content-left">
                            <h1 className="text-primary button-2">Spot Income</h1>
                        </div>
                        <h6 className="text-primary">${myStake.spotIncome || '0'}</h6>
                    </div>
                    </a>
                    <a href="/stake-level-income">
                    <div className="d-flex justify-content-between align-items-center pb-12 mb-12 line-2">
                        <div className="content-left">
                            <h1 className="text-primary button-2">Level Income</h1>
                        </div>
                        <h6 className="text-primary">${myStake.levelIncome || '0'}</h6>
                    </div>
                    </a>

                    <div className="d-flex justify-content-between align-items-center pb-12 mb-12 line-2">
                        <div className="content-left">
                            <h1 className="text-primary button-2">Rank Reward</h1>
                        </div>
                        <h6 className="text-primary">${myStake.rankReward || '0'}</h6>
                    </div>
                </div>
            </div>       
        </div>

        <div className="card-nft-1 mt-24">
            <div className="card-header" data-bs-toggle="collapse" data-bs-target="#business" aria-expanded="true" aria-controls="business">
                <h6>Business</h6>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.9201 8.94922L13.4001 15.4692C12.6301 16.2392 11.3701 16.2392 10.6001 15.4692L4.08008 8.94922" stroke="#1A1528" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>    
            </div>
            <div id="business" className="accordion-collapse collapse show" aria-labelledby="incomes">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center pb-12 mb-12 line-2">
                        <div className="content-left">
                            <h1 className="text-primary button-2">Month Business</h1>
                        </div>
                        <h6 className="text-primary">${'0'}</h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pb-12 mb-12 line-2">
                        <div className="content-left">
                            <h1 className="text-primary button-2">Direct Business</h1>
                        </div>
                        <h6 className="text-primary">${myStake.directBusiness || '0'}</h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pb-12 mb-12 line-2">
                        <div className="content-left">
                            <h1 className="text-primary button-2">Total Business</h1>
                        </div>
                        <h6 className="text-primary">${myStake.totalBusiness || "0"}</h6>
                    </div>
                </div>
            </div>       
        </div>

        <div className="card-nft-1 mt-24">
            <div className="card-header" data-bs-toggle="collapse" data-bs-target="#myteam" aria-expanded="true" aria-controls="myteam">
                <h6>My Team</h6>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.9201 8.94922L13.4001 15.4692C12.6301 16.2392 11.3701 16.2392 10.6001 15.4692L4.08008 8.94922" stroke="#1A1528" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>    
            </div>
            <div id="myteam" className="accordion-collapse collapse show" aria-labelledby="incomes">
                <div className="card-body">
                    <a href="/stake-direct-referral">
                    <div className="d-flex justify-content-between align-items-center pb-12 mb-12 line-2">
                        <div className="content-left">
                            <h1 className="text-primary button-2">Direct Referral</h1>
                        </div>
                        <h6 className="text-primary">{myStake.directReferral || '0'}</h6>
                    </div>
                    </a>

                   <a href="/stake-total-team">
                    <div className="d-flex justify-content-between align-items-center pb-12 mb-12 line-2">
                        <div className="content-left">
                            <h1 className="text-primary button-2">Total Team</h1>
                        </div>
                        <h6 className="text-primary">{myStake.totalTeam || '0'}</h6>
                    </div>
                    </a>
                </div>
            </div>       
        </div>

        <div className="d-flex align-center gap-12 tf-counter mb-20 mt-24"
            style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "12px", marginBottom: "20px", marginTop: "24px" }}
        >
            <a href="#" style={{ textDecoration: "none" }}>
                <div className="counter-box" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h6 className="text-white" style={{ color: "#fff" }}>${myStake.totalIincome || '0'}</h6>
                    <div className="title-count">TOTAL INCOME</div>
                </div>
            </a>
            <a href="">
            <div className="counter-box count-2" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div className="d-flex align-items-center gap-5" style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <h6 className="text-white" style={{ color: "#fff" }}>${myStake.withdrawIncme || '0'}</h6>
                </div>
                <div className="title-count">WITHDRAW AMT</div>
            </div>
            </a>

            <a href="/stake-withdraw">
            <div className="counter-box count-3" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <h6 className="text-white" style={{ color: "#fff" }}>${myStake.remainIncome || '0'}</h6>
                <div className="title-count">AVAILABLE AMT</div>
            </div>
            </a>
        </div>

        <div class="mt-24 card">
                <div class="d-flex justify-content-between align-items-center mb-24">
                    <h4>Stake List</h4>
                </div>
                <ul class="mt-20 list-view-v7">
                    {stakeList.length > 0 ? (
                    stakeList.map((mySlot) => (
                      <li key={mySlot.id}  >
                            <div  class="item">
                            <div class="flex-grow-1 d-flex gap-16 align-items-center">
                                <div class="avatar round avt-50">
                                    <img src="assets/images/avt/piratesicon.jpeg" alt=""/>
                                </div>
                                <div class="content d-flex justify-content-between align-items-center">
                                    <div class="content-left">
                                        <p class="button-1">${mySlot.stakeAmt } 
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                {mySlot.status === 'active' ? (
                                                         <path
                                                         d="M14.3731 7.16036L13.4664 6.10703C13.2931 5.90703 13.1531 5.5337 13.1531 5.26703V4.1337C13.1531 3.42703 12.5731 2.84703 11.8664 2.84703H10.7331C10.4731 2.84703 10.0931 2.70703 9.89309 2.5337L8.83976 1.62703C8.37976 1.2337 7.62643 1.2337 7.15976 1.62703L6.11309 2.54036C5.91309 2.70703 5.53309 2.84703 5.27309 2.84703H4.11976C3.41309 2.84703 2.83309 3.42703 2.83309 4.1337V5.2737C2.83309 5.5337 2.69309 5.90703 2.52643 6.10703L1.62643 7.16703C1.23976 7.62703 1.23976 8.3737 1.62643 8.8337L2.52643 9.8937C2.69309 10.0937 2.83309 10.467 2.83309 10.727V11.867C2.83309 12.5737 3.41309 13.1537 4.11976 13.1537H5.27309C5.53309 13.1537 5.91309 13.2937 6.11309 13.467L7.16643 14.3737C7.62643 14.767 8.37976 14.767 8.84643 14.3737L9.89976 13.467C10.0998 13.2937 10.4731 13.1537 10.7398 13.1537H11.8731C12.5798 13.1537 13.1598 12.5737 13.1598 11.867V10.7337C13.1598 10.4737 13.2998 10.0937 13.4731 9.8937L14.3798 8.84036C14.7664 8.38036 14.7664 7.62036 14.3731 7.16036ZM10.7731 6.74036L7.55309 9.96036C7.45976 10.0537 7.33309 10.107 7.19976 10.107C7.06643 10.107 6.93976 10.0537 6.84643 9.96036L5.23309 8.34703C5.03976 8.1537 5.03976 7.8337 5.23309 7.64036C5.42643 7.44703 5.74643 7.44703 5.93976 7.64036L7.19976 8.90036L10.0664 6.0337C10.2598 5.84036 10.5798 5.84036 10.7731 6.0337C10.9664 6.22703 10.9664 6.54703 10.7731 6.74036Z"
                                                         fill="#02bf05" />
                                                    ) : (
                                                      <path
                                                         d="M14.3731 7.16036L13.4664 6.10703C13.2931 5.90703 13.1531 5.5337 13.1531 5.26703V4.1337C13.1531 3.42703 12.5731 2.84703 11.8664 2.84703H10.7331C10.4731 2.84703 10.0931 2.70703 9.89309 2.5337L8.83976 1.62703C8.37976 1.2337 7.62643 1.2337 7.15976 1.62703L6.11309 2.54036C5.91309 2.70703 5.53309 2.84703 5.27309 2.84703H4.11976C3.41309 2.84703 2.83309 3.42703 2.83309 4.1337V5.2737C2.83309 5.5337 2.69309 5.90703 2.52643 6.10703L1.62643 7.16703C1.23976 7.62703 1.23976 8.3737 1.62643 8.8337L2.52643 9.8937C2.69309 10.0937 2.83309 10.467 2.83309 10.727V11.867C2.83309 12.5737 3.41309 13.1537 4.11976 13.1537H5.27309C5.53309 13.1537 5.91309 13.2937 6.11309 13.467L7.16643 14.3737C7.62643 14.767 8.37976 14.767 8.84643 14.3737L9.89976 13.467C10.0998 13.2937 10.4731 13.1537 10.7398 13.1537H11.8731C12.5798 13.1537 13.1598 12.5737 13.1598 11.867V10.7337C13.1598 10.4737 13.2998 10.0937 13.4731 9.8937L14.3798 8.84036C14.7664 8.38036 14.7664 7.62036 14.3731 7.16036ZM10.7731 6.74036L7.55309 9.96036C7.45976 10.0537 7.33309 10.107 7.19976 10.107C7.06643 10.107 6.93976 10.0537 6.84643 9.96036L5.23309 8.34703C5.03976 8.1537 5.03976 7.8337 5.23309 7.64036C5.42643 7.44703 5.74643 7.44703 5.93976 7.64036L7.19976 8.90036L10.0664 6.0337C10.2598 5.84036 10.5798 5.84036 10.7731 6.0337C10.9664 6.22703 10.9664 6.54703 10.7731 6.74036Z"
                                                         fill="#bf0202" />
                                                    )} 
                                            </svg>
                                            
                                        </p>
                                        <div class="mt-2 d-flex align-items-center">
                                                <span class="body-3 d-flex align-items-center">
                                                    
                                                    {mySlot.join_date}
                                                </span>
                                            </div>
                                    </div>
                                    <div class="content-right">
                                        {mySlot.status === 'active' ?(
                                            <a class="button active" onClick={() => minetStake(mySlot.id)}>Mine</a>

                                        ):(
                                            <a class="button active" onClick={() => viewStake(mySlot.id)} >View</a>

                                        )}

                                    </div>
                                </div>
                            </div>

                        </div>
                        </li>
                    ))
                  ) : (
                    <p>No data available.</p>
                  )}
                </ul>
                <div className="mb-20">

                </div>
                <div className="mb-20">

                </div>
                <div className="mb-20">

                </div>
                <div className="mb-20">

                </div>
                <div className="mb-20">

                </div>
                

                
            </div>



    <div className="btn-fixed">
        <div className="gap-15">
            <a href="/stake-stake" className="tf-btn primary btn-icon"><span className=""></span>STAKE NOW</a>

        </div>
    </div>
        </div>    


    </>
  )
}

export default Dashboard