import { useEffect, useState } from 'react';
import Auth from '../component/auth';
import { toast } from 'react-toastify';

function Setting() {
    const [profileId,setProfileId] = useState(null);
    const [sponsorId, setSponsorId] = useState(null);

    useEffect( async () => {
        const deviceToken = localStorage.getItem('deviceToken');
          await fetchProfileData(deviceToken);
    
      }, []);




    const fetchProfileData = async (token) => {
    
        try {
          const response = await fetch('https://api.cinu.io/api/profile', {
            method: 'GET', // Use GET method
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`, // Send the token in the Authorization header
            },
          });
    
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
    
          const result = await response.json();
    
          if (result.status){
            setProfileId(result.USER.user_id);
            setSponsorId(result.USER.sponsor_id);
    
          }
    
        } catch (error) {

        } finally {
        //  setLoading(false);
        }
      };

    const handleShare = () => {

        navigator.clipboard.writeText('Hi, I’m farming CINU tokens on CARO Games! Use my referral code or register with my link and get 100,000 CINU tokens for free to kickstart your journey  https://wallet.cinu.io/register?invite='+profileId)
      .then(() => {
        toast.success('Copy Success!');
      })
      .catch(err => {
        toast.error('Failed to copy.');
      });

        if (navigator.share) {
          navigator.share({
            title: 'Join C3 Club and Explore unlimited Reward',
            text:  'Hi, I’m farming CINU tokens on CARO Games! Use my referral code or register with my link and get 100,000 CINU tokens for free to kickstart your journey   https://wallet.cinu.io/register?invite='+profileId,
          }).catch((error) => console.log('Error sharing:', error));
        } else {
        }
    }

    return (
        <>
        <Auth/>
            <div className="header fixed-top">
                <div className="left">
                    <a href="/more" className="icon back-btn">
                        <i className="icon-arrow-right" />
                    </a>
                </div>
                <h3>Setting</h3>
            </div>
            <div className="app-content style-1">
                <div className="tf-container">
                    <ul className="list-view-v2 mb-28 pb-28 line">
                    
                        <li>
                            <a
                                href="wallet"
                                className="item"
                            >
                                <span className="icon icon-wallet-money" />
                                <span className="body-1 content">E-Wallet</span>
                                <svg
                                    width={20}
                                    height={20}
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g opacity="0.6">
                                        <path
                                            d="M7.42505 16.6004L12.8584 11.1671C13.5 10.5254 13.5 9.47539 12.8584 8.83372L7.42505 3.40039"
                                            stroke="#1A1528"
                                            strokeWidth="1.5"
                                            strokeMiterlimit={10}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </g>
                                </svg>
                            </a>
                        </li>
                    

                        {/* <li>
                            <a
                                href="#notification"
                                className="item"
                                data-bs-toggle="offcanvas"
                                aria-controls="offcanvasRight"
                            >
                                <span className="icon icon-notification-bing" />
                                <span className="body-1 content">Notification</span>
                                <svg
                                    width={20}
                                    height={20}
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g opacity="0.6">
                                        <path
                                            d="M7.42505 16.6004L12.8584 11.1671C13.5 10.5254 13.5 9.47539 12.8584 8.83372L7.42505 3.40039"
                                            stroke="#1A1528"
                                            strokeWidth="1.5"
                                            strokeMiterlimit={10}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </g>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a
                                href="#security"
                                className="item"
                                data-bs-toggle="offcanvas"
                                aria-controls="offcanvasRight"
                            >
                                <span className="icon icon-shield-tick" />
                                <span className="body-1 content">Security</span>
                                <svg
                                    width={20}
                                    height={20}
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g opacity="0.6">
                                        <path
                                            d="M7.42505 16.6004L12.8584 11.1671C13.5 10.5254 13.5 9.47539 12.8584 8.83372L7.42505 3.40039"
                                            stroke="#1A1528"
                                            strokeWidth="1.5"
                                            strokeMiterlimit={10}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </g>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a
                                href="#language"
                                className="item"
                                data-bs-toggle="offcanvas"
                                aria-controls="offcanvasRight"
                            >
                                <span className="icon icon-language-square" />
                                <span className="body-1 content">Language</span>
                                <svg
                                    width={20}
                                    height={20}
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g opacity="0.6">
                                        <path
                                            d="M7.42505 16.6004L12.8584 11.1671C13.5 10.5254 13.5 9.47539 12.8584 8.83372L7.42505 3.40039"
                                            stroke="#1A1528"
                                            strokeWidth="1.5"
                                            strokeMiterlimit={10}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </g>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="#" className="item">
                                <span className="icon icon-eye" />
                                <label htmlFor="switchCheckDefault" className="body-1 content">
                                    Dark Mode
                                </label>
                                <input
                                    type="checkbox"
                                    className="tf-switch-check toggle-theme"
                                    id="switchCheckDefault"
                                />
                            </a>
                        </li> */}
                        <li>
                            <a onClick={handleShare}  href="#inviteFriends"  className="item" >
                                <span className="icon icon-people" />
                                <span className="body-1 content">Invite Friends</span>
                                <svg
                                    width={20}
                                    height={20}
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g opacity="0.6">
                                        <path
                                            d="M7.42505 16.6004L12.8584 11.1671C13.5 10.5254 13.5 9.47539 12.8584 8.83372L7.42505 3.40039"
                                            stroke="#1A1528"
                                            strokeWidth="1.5"
                                            strokeMiterlimit={10}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </g>
                                </svg>
                            </a>
                        </li>
                        { sponsorId === '0' && (
                        <li>
                            <a href="/addsponsor" className="item">
                                <span className="icon icon-info-circle" />
                                <span className="body-1 content">Add Sponsor</span>
                                <svg
                                    width={20}
                                    height={20}
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g opacity="0.6">
                                        <path
                                            d="M7.42505 16.6004L12.8584 11.1671C13.5 10.5254 13.5 9.47539 12.8584 8.83372L7.42505 3.40039"
                                            stroke="#1A1528"
                                            strokeWidth="1.5"
                                            strokeMiterlimit={10}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </g>
                                </svg>
                            </a>
                        </li>
                        )}
                        <li>
                            <a href="#" className="item">
                                <span className="icon icon-star" />
                                <span className="body-1 content">Rate us</span>
                                <svg
                                    width={20}
                                    height={20}
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g opacity="0.6">
                                        <path
                                            d="M7.42505 16.6004L12.8584 11.1671C13.5 10.5254 13.5 9.47539 12.8584 8.83372L7.42505 3.40039"
                                            stroke="#1A1528"
                                            strokeWidth="1.5"
                                            strokeMiterlimit={10}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </g>
                                </svg>
                            </a>
                        </li>
                    </ul>
                    <h4>About</h4>
                    <ul className="list-view-v2 mt-20">
                        <li>
                            <a href="#" className="item">
                                <span className="icon icon-shield-tick" />
                                <span className="body-1 content">Privacy &amp; Policy</span>
                                <svg
                                    width={20}
                                    height={20}
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g opacity="0.6">
                                        <path
                                            d="M7.42505 16.6004L12.8584 11.1671C13.5 10.5254 13.5 9.47539 12.8584 8.83372L7.42505 3.40039"
                                            stroke="#1A1528"
                                            strokeWidth="1.5"
                                            strokeMiterlimit={10}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </g>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="#" className="item">
                                <span className="icon icon-key-square" />
                                <span className="body-1 content">Terms of Services</span>
                                <svg
                                    width={20}
                                    height={20}
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g opacity="0.6">
                                        <path
                                            d="M7.42505 16.6004L12.8584 11.1671C13.5 10.5254 13.5 9.47539 12.8584 8.83372L7.42505 3.40039"
                                            stroke="#1A1528"
                                            strokeWidth="1.5"
                                            strokeMiterlimit={10}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </g>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="#" className="item">
                                <span className="icon icon-info-circle-2" />
                                <span className="body-1 content">About us</span>
                                <svg
                                    width={20}
                                    height={20}
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g opacity="0.6">
                                        <path
                                            d="M7.42505 16.6004L12.8584 11.1671C13.5 10.5254 13.5 9.47539 12.8584 8.83372L7.42505 3.40039"
                                            stroke="#1A1528"
                                            strokeWidth="1.5"
                                            strokeMiterlimit={10}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </g>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            {/* e wallet */}
            <div className="offcanvas offcanvas-end full" id="eWallet">
                <div className="header fixed-top">
                    <div className="left">
                        <a
                            href="javascript:void(0);"
                            data-bs-dismiss="offcanvas"
                            className="icon"
                        >
                            <i className="icon-arrow-right" />
                        </a>
                    </div>
                    <h3>E-Wallet</h3>
                </div>
                <div className="overflow-auto app-content style-1">
                    <div className="tf-container">
                        <ul className="list-view-v3">
                            <li className="item">
                                <a href="#">
                                    <div className="box-icon w-32">
                                        <img
                                            className="lazyload"
                                            data-src="assets/images/wallet/wallet-1.png"
                                            alt="img-wallet"
                                        />
                                    </div>
                                    <label htmlFor="sw1" className="content">
                                        Metamask
                                    </label>
                                    <input
                                        type="checkbox"
                                        className="tf-switch-check"
                                        id="sw1"
                                        defaultChecked=""
                                    />
                                </a>
                            </li>
                            <li className="item">
                                <a href="#">
                                    <div className="box-icon w-32">
                                        <img
                                            className="lazyload"
                                            data-src="assets/images/wallet/wallet-2.png"
                                            alt="img-wallet"
                                        />
                                    </div>
                                    <label htmlFor="sw2" className="content">
                                        Coinbase Wallet
                                    </label>
                                    <input type="checkbox" className="tf-switch-check" id="sw2" />
                                </a>
                            </li>
                            <li className="item">
                                <a href="#">
                                    <div className="box-icon w-32">
                                        <img
                                            className="lazyload"
                                            data-src="assets/images/wallet/wallet-3.png"
                                            alt="img-wallet"
                                        />
                                    </div>
                                    <label htmlFor="sw3" className="content">
                                        WalletConnect
                                    </label>
                                    <input type="checkbox" className="tf-switch-check" id="sw3" />
                                </a>
                            </li>
                            <li className="item">
                                <a href="#">
                                    <div className="box-icon w-32">
                                        <img
                                            className="lazyload"
                                            data-src="assets/images/wallet/wallet-4.png"
                                            alt="img-wallet"
                                        />
                                    </div>
                                    <label htmlFor="sw4" className="content">
                                        Trust Wallet
                                    </label>
                                    <input type="checkbox" className="tf-switch-check" id="sw4" />
                                </a>
                            </li>
                            <li className="item">
                                <a href="#">
                                    <div className="box-icon w-32">
                                        <img
                                            className="lazyload"
                                            data-src="assets/images/wallet/wallet-5.png"
                                            alt="img-wallet"
                                        />
                                    </div>
                                    <label htmlFor="sw5" className="content">
                                        Fortmatic
                                    </label>
                                    <input type="checkbox" className="tf-switch-check" id="sw5" />
                                </a>
                            </li>
                            <li className="item">
                                <a href="#">
                                    <div className="box-icon w-32">
                                        <img
                                            className="lazyload"
                                            data-src="assets/images/wallet/wallet-6.png"
                                            alt="img-wallet"
                                        />
                                    </div>
                                    <label htmlFor="sw6" className="content">
                                        Phantom
                                    </label>
                                    <input type="checkbox" className="tf-switch-check" id="sw6" />
                                </a>
                            </li>
                            <li className="item">
                                <a href="#">
                                    <div className="box-icon w-32">
                                        <img
                                            className="lazyload"
                                            data-src="assets/images/wallet/wallet-7.png"
                                            alt="img-wallet"
                                        />
                                    </div>
                                    <label htmlFor="sw7" className="content">
                                        Glow
                                    </label>
                                    <input type="checkbox" className="tf-switch-check" id="sw7" />
                                </a>
                            </li>
                            <li className="item">
                                <a href="#">
                                    <div className="box-icon w-32">
                                        <img
                                            className="lazyload"
                                            data-src="assets/images/wallet/wallet-8.png"
                                            alt="img-wallet"
                                        />
                                    </div>
                                    <label htmlFor="sw8" className="content">
                                        Solflare
                                    </label>
                                    <input type="checkbox" className="tf-switch-check" id="sw8" />
                                </a>
                            </li>
                            <li className="item">
                                <a href="#">
                                    <div className="box-icon w-32">
                                        <img
                                            className="lazyload"
                                            data-src="assets/images/wallet/wallet-9.png"
                                            alt="img-wallet"
                                        />
                                    </div>
                                    <label htmlFor="sw9" className="content">
                                        Bitski
                                    </label>
                                    <input type="checkbox" className="tf-switch-check" id="sw9" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* notification */}
            <div className="offcanvas offcanvas-end full" id="notification">
                <div className="header fixed-top">
                    <div className="left">
                        <a
                            href="javascript:void(0);"
                            data-bs-dismiss="offcanvas"
                            className="icon"
                        >
                            <i className="icon-arrow-right" />
                        </a>
                    </div>
                    <h3>Notification</h3>
                </div>
                <div className="overflow-auto app-content style-1">
                    <div className="tf-container">
                        <ul className="list-view-v4">
                            <li>
                                <a href="#" className="item">
                                    <label htmlFor="sw-noti1" className="content body-1">
                                        General Notification
                                    </label>
                                    <input
                                        type="checkbox"
                                        className="tf-switch-check"
                                        id="sw-noti1"
                                        defaultChecked=""
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="#" className="item">
                                    <label htmlFor="sw-noti2" className="content body-1">
                                        Sound
                                    </label>
                                    <input
                                        type="checkbox"
                                        className="tf-switch-check"
                                        id="sw-noti2"
                                        defaultChecked=""
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="#" className="item">
                                    <label htmlFor="sw-noti3" className="content body-1">
                                        Vibrate
                                    </label>
                                    <input
                                        type="checkbox"
                                        className="tf-switch-check"
                                        id="sw-noti3"
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="#" className="item">
                                    <label htmlFor="sw-noti4" className="content body-1">
                                        Notify me when there is a sale
                                    </label>
                                    <input
                                        type="checkbox"
                                        className="tf-switch-check"
                                        id="sw-noti4"
                                        defaultChecked=""
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="#" className="item">
                                    <label htmlFor="sw-noti5" className="content body-1">
                                        Notify me when there is an offer
                                    </label>
                                    <input
                                        type="checkbox"
                                        className="tf-switch-check"
                                        id="sw-noti5"
                                        defaultChecked=""
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="#" className="item">
                                    <label htmlFor="sw-noti6" className="content body-1">
                                        Notify me when there is an activity
                                    </label>
                                    <input
                                        type="checkbox"
                                        className="tf-switch-check"
                                        id="sw-noti6"
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="#" className="item">
                                    <label htmlFor="sw-noti7" className="content body-1">
                                        App Updates
                                    </label>
                                    <input
                                        type="checkbox"
                                        className="tf-switch-check"
                                        id="sw-noti7"
                                        defaultChecked=""
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="#" className="item">
                                    <label htmlFor="sw-noti8" className="content body-1">
                                        New Services Available
                                    </label>
                                    <input
                                        type="checkbox"
                                        className="tf-switch-check"
                                        id="sw-noti8"
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="#" className="item">
                                    <label htmlFor="sw-noti9" className="content body-1">
                                        New Tips Available
                                    </label>
                                    <input
                                        type="checkbox"
                                        className="tf-switch-check"
                                        id="sw-noti9"
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* security */}
            <div className="offcanvas offcanvas-end full" id="security">
                <div className="header fixed-top">
                    <div className="left">
                        <a
                            href="javascript:void(0);"
                            data-bs-dismiss="offcanvas"
                            className="icon"
                        >
                            <i className="icon-arrow-right" />
                        </a>
                    </div>
                    <h3>Security</h3>
                </div>
                <div className="overflow-auto app-content style-1">
                    <div className="tf-container">
                        <ul className="list-view-v4">
                            <li>
                                <a href="#" className="item">
                                    <label htmlFor="sw-security1" className="content body-1">
                                        Remember me
                                    </label>
                                    <input
                                        type="checkbox"
                                        className="tf-switch-check"
                                        id="sw-security1"
                                        defaultChecked=""
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="#" className="item">
                                    <label htmlFor="sw-security2" className="content body-1">
                                        Biometric ID
                                    </label>
                                    <input
                                        type="checkbox"
                                        className="tf-switch-check"
                                        id="sw-security2"
                                        defaultChecked=""
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="#" className="item">
                                    <label htmlFor="sw-security3" className="content body-1">
                                        Face ID
                                    </label>
                                    <input
                                        type="checkbox"
                                        className="tf-switch-check"
                                        id="sw-security3"
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="#" className="item">
                                    <span className="content body-1">Google Authenticator</span>
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            opacity="0.6"
                                            d="M8.90991 19.9201L15.4299 13.4001C16.1999 12.6301 16.1999 11.3701 15.4299 10.6001L8.90991 4.08008"
                                            stroke="#1A1528"
                                            strokeWidth="1.5"
                                            strokeMiterlimit={10}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/*language  */}
            <div className="offcanvas offcanvas-end full" id="language">
                <div className="header fixed-top">
                    <div className="left">
                        <a
                            href="javascript:void(0);"
                            data-bs-dismiss="offcanvas"
                            className="icon"
                        >
                            <i className="icon-arrow-right" />
                        </a>
                    </div>
                    <h3>Language</h3>
                </div>
                <div className="overflow-auto app-content style-1">
                    <div className="tf-container">
                        <h4>Suggested</h4>
                        <ul className="mt-32 mb-24 pb-24 line">
                            <li className="d-flex align-items-center justify-content-between mb-28">
                                <label htmlFor="lg1" className="body-1 flex-grow-1">
                                    English (US)
                                </label>
                                <input
                                    type="radio"
                                    name="radio1"
                                    className="tf-radio"
                                    id="lg1"
                                    defaultChecked=""
                                />
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                                <label htmlFor="lg2" className="body-1 flex-grow-1">
                                    English (UK)
                                </label>
                                <input type="radio" name="radio1" className="tf-radio" id="lg2" />
                            </li>
                        </ul>
                        <h4>Languages</h4>
                        <ul className="mt-28">
                            <li className="d-flex align-items-center justify-content-between mb-32">
                                <label htmlFor="lg3" className="body-1 flex-grow-1">
                                    Mandarin
                                </label>
                                <input type="radio" name="radio2" className="tf-radio" id="lg3" />
                            </li>
                            <li className="d-flex align-items-center justify-content-between mb-32">
                                <label htmlFor="lg4" className="body-1 flex-grow-1">
                                    Hindi
                                </label>
                                <input type="radio" name="radio2" className="tf-radio" id="lg4" />
                            </li>
                            <li className="d-flex align-items-center justify-content-between mb-32">
                                <label htmlFor="lg5" className="body-1 flex-grow-1">
                                    Spanish
                                </label>
                                <input type="radio" name="radio2" className="tf-radio" id="lg5" />
                            </li>
                            <li className="d-flex align-items-center justify-content-between mb-32">
                                <label htmlFor="lg6" className="body-1 flex-grow-1">
                                    French
                                </label>
                                <input type="radio" name="radio2" className="tf-radio" id="lg6" />
                            </li>
                            <li className="d-flex align-items-center justify-content-between mb-32">
                                <label htmlFor="lg7" className="body-1 flex-grow-1">
                                    Arabic
                                </label>
                                <input type="radio" name="radio2" className="tf-radio" id="lg7" />
                            </li>
                            <li className="d-flex align-items-center justify-content-between mb-32">
                                <label htmlFor="lg8" className="body-1 flex-grow-1">
                                    Bengali
                                </label>
                                <input type="radio" name="radio2" className="tf-radio" id="lg8" />
                            </li>
                            <li className="d-flex align-items-center justify-content-between mb-32">
                                <label htmlFor="lg9" className="body-1 flex-grow-1">
                                    English (UK)
                                </label>
                                <input type="radio" name="radio2" className="tf-radio" id="lg9" />
                            </li>
                            <li className="d-flex align-items-center justify-content-between mb-32">
                                <label htmlFor="lg10" className="body-1 flex-grow-1">
                                    Russian
                                </label>
                                <input type="radio" name="radio2" className="tf-radio" id="lg10" />
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                                <label htmlFor="lg11" className="body-1 flex-grow-1">
                                    Indonesia
                                </label>
                                <input type="radio" name="radio2" className="tf-radio" id="lg11" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/*Invite Friends  */}
            <div className="offcanvas offcanvas-end full" id="inviteFriends">
                <div className="header fixed-top">
                    <div className="left">
                        <a
                            href="javascript:void(0);"
                            data-bs-dismiss="offcanvas"
                            className="icon"
                        >
                            <i className="icon-arrow-right" />
                        </a>
                    </div>
                    <h3>Invite Friends</h3>
                </div>
                <div className="overflow-auto app-content style-1">
                    <div className="tf-container">
                        <ul className="list-view-v5">
                            <li className="item">
                                <div className="avatar avt-50 round">
                                    <img src="assets/images/avt/avt1.png" alt="avt" />
                                </div>
                                <div className="content">
                                    <div className="title">Marvin McKinney</div>
                                    <p className="phone">(219) 555-0114</p>
                                </div>
                                <a href="#" className="btn-invite">
                                    Invited
                                </a>
                            </li>
                            <li className="item">
                                <div className="avatar avt-50 round">
                                    <img src="assets/images/avt/avt2.png" alt="avt" />
                                </div>
                                <div className="content">
                                    <div className="title">Wade Warren</div>
                                    <p className="phone">(225) 555-0118</p>
                                </div>
                                <a href="#" className="btn-invite active">
                                    Invited
                                </a>
                            </li>
                            <li className="item">
                                <div className="avatar avt-50 round">
                                    <img src="assets/images/avt/avt3.png" alt="avt" />
                                </div>
                                <div className="content">
                                    <div className="title">Theresa Webb</div>
                                    <p className="phone">(684) 555-0102</p>
                                </div>
                                <a href="#" className="btn-invite">
                                    Invited
                                </a>
                            </li>
                            <li className="item">
                                <div className="avatar avt-50 round">
                                    <img src="assets/images/avt/avt4.png" alt="avt" />
                                </div>
                                <div className="content">
                                    <div className="title">Brooklyn Simmons</div>
                                    <p className="phone">(229) 555-0109</p>
                                </div>
                                <a href="#" className="btn-invite">
                                    Invited
                                </a>
                            </li>
                            <li className="item">
                                <div className="avatar avt-50 round">
                                    <img src="assets/images/avt/avt5.png" alt="avt" />
                                </div>
                                <div className="content">
                                    <div className="title">Devon Lane</div>
                                    <p className="phone">(907) 555-0101</p>
                                </div>
                                <a href="#" className="btn-invite active">
                                    Invited
                                </a>
                            </li>
                            <li className="item">
                                <div className="avatar avt-50 round">
                                    <img src="assets/images/avt/avt6.png" alt="avt" />
                                </div>
                                <div className="content">
                                    <div className="title">Dianne Russell</div>
                                    <p className="phone">(303) 555-0105</p>
                                </div>
                                <a href="#" className="btn-invite active">
                                    Invited
                                </a>
                            </li>
                            <li className="item">
                                <div className="avatar avt-50 round">
                                    <img src="assets/images/avt/avt7.png" alt="avt" />
                                </div>
                                <div className="content">
                                    <div className="title">Albert Flores</div>
                                    <p className="phone">(252) 555-0126</p>
                                </div>
                                <a href="#" className="btn-invite">
                                    Invited
                                </a>
                            </li>
                            <li className="item">
                                <div className="avatar avt-50 round">
                                    <img src="assets/images/avt/avt8.png" alt="avt" />
                                </div>
                                <div className="content">
                                    <div className="title">Esther Howard</div>
                                    <p className="phone">(702) 555-0122</p>
                                </div>
                                <a href="#" className="btn-invite active">
                                    Invited
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Setting