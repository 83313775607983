import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Web3 from 'web3';
import { toast } from 'react-toastify';
import Auth from '../../component/auth';

const Mining = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userid');
  const web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/'));
  
  const [stakeData, setStakeData] = useState([null]);
  const [error, setError] = useState(null);
  const deviceToken = localStorage.getItem('deviceToken');
  const [showLoader, setShowLoader] = useState(true);
  const [showButton, setShowButton] = useState(true);
 
  useEffect(() => {
    const fetchStakeDetails = async () => {
        try {

          const response = await fetch('https://api.cinu.io/api/stake/details', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${deviceToken}`,
            },
            body: JSON.stringify({
                stakeId: localStorage.getItem('stakeId'),
            }),
          });
    
          if (!response.ok) throw new Error('Failed to submit stake');
          const result = await response.json();
          if(result.status){
            setStakeData(result.data);
                console.log(result)
                setShowLoader(false);
          }
          else
          {
            toast.alert(result.message);
          }
          // Handle any additional logic or state updates here if needed
        } catch (error) {
          toast.error(error.message || "Failed to add stake");
    
          setShowLoader(false);
        }
    };
   fetchStakeDetails();
  }, [userId]);


  const mining= async ()=>{
    try {

      const responseMining = await fetch('https://api.cinu.io/api/stake/mining', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${deviceToken}`,
        },
        body: JSON.stringify({
            stakeId: localStorage.getItem('stakeId'),
        }),
      });

      if (!responseMining.ok) throw new Error('Failed to submit stake');
      const resultMining = await responseMining.json();
      if(resultMining.status){
            console.log(resultMining)
            toast.success(resultMining.message);

            

            setTimeout(() => {
             window.location.href =""
            }, 3000);
            
      }
      else
      {
        toast.alert(resultMining.message);
      }
      // Handle any additional logic or state updates here if needed
    } catch (error) {
      toast.error(error.message || "Failed to add stake");

      setShowLoader(false);
    }


  }

 

  return (
    <>
      <Auth />
      <div className="app-content style-2">
        <div className="header fixed-top">
          <div className="left">
            <a href="/stake-dashboard" className="icon back-btn">
              <i className="icon-arrow-right"></i>
            </a>
          </div>
          <h3>STAKE DETAILS</h3>
        </div>

            <div class="card-nft-1 mt-24">
                <div class="card-header" data-bs-toggle="collapse" data-bs-target="#details-accordion" aria-expanded="true" aria-controls="details-accordion">
                    <h6>Details</h6>  
                </div>
                <div id="details-accordion" class="accordion-collapse collapse show" aria-labelledby="details-accordion">
                    <div class="card-body">
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">Stake Amount</span>
                            <span class="button-2 text-primary">${stakeData.stakeAmt}</span>
                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">CINU Reward</span>
                            <span class="button-2 text-primary"><b className="text-danger">C</b> {stakeData.claimCinu} <a className="btn btn-sm btn-primary ">Claim</a></span>
                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">Total Reward</span>
                            <span class="button-2 text-primary">${stakeData.return}</span>
                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">Total Minting</span>
                            <span class="button-2 text-primary">${stakeData.credit}</span>
                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">Remain Amount</span>
                            <span class="button-2 text-primary">${stakeData.remain}</span>
                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">Today Minting</span>
                            <span class="button-2 text-primary">{stakeData.todayMinting}</span>
                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">Stake For</span>
                            <span class="button-2 text-primary">{stakeData.stakeFor} Days </span>
                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">Stake Age</span>
                            <span class="button-2 text-primary">{stakeData.mintAge} Days</span>
                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">Last Mining</span>
                            <span class="button-2 text-primary">{stakeData.last_update}</span>
                        </div>



                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-danger">Income Details...</span>
                        </div>

                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">Stake Reward</span>
                            <span class="button-2 text-primary">$ {stakeData.credit} </span>
                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">Spot Income</span>
                            <span class="button-2 text-primary">$ {stakeData.spotIncome} </span>
                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">Level Income</span>
                            <span class="button-2 text-primary">$ {stakeData.levelIncome}</span>
                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">Reward Income</span>
                            <span class="button-2 text-primary">$ {stakeData.rewardIncome}</span>
                        </div>
                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">Total Income</span>
                            <span class="button-2 text-primary">$ {stakeData.totalIncome}</span>
                        </div>

                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">Capping</span>
                            <span class="button-2 text-primary">$ {stakeData.capping}</span>
                        </div>

                        <div class="mb-20 d-flex justify-content-between align-items-center">
                            <span class="body-3 text-dark-2">Remain Amount</span>
                            <span class="button-2 text-primary">$ {stakeData.capping-stakeData.totalIncome}</span>
                        </div>

                    </div>
                </div>       
            </div>
        <div class="px-24 style-2">
            <div class=" mt-24 text-center">
            <h6 class="text-danger">{error}</h6>       
            </div>
        </div>
        
    </div>

      <div className="btn-fixed">
        <div className="gap-15">
          


              {showLoader ? (
                <div class="text-center" >
              <img src="assets/images/loadingIcon.gif" alt="Loading" style={{ width: '50%' }}/>
              </div>
              ):(
                <a onClick={mining} className="tf-btn primary btn-icon"> <span className=""></span>CLAIM REWARD</a>

              )}
        </div>
      </div>
    </>
  );
};

export default Mining;
