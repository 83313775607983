import React, { useState } from 'react';
import { FiMail, FiLock, FiEye, FiEyeOff } from 'react-icons/fi'; // Import icons from react-icons
import { toast } from 'react-toastify';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    // Handle login logic here
    try {
      const postData = {
        email: email,
        password: password,
      };

      const responseRegister = await fetch('https://api.cinu.io/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      if (!responseRegister.ok) {
        const errorText = await responseRegister.text();
        throw new Error(`HTTP error! status: ${responseRegister.status}`);
      }

      const resultRegister = await responseRegister.json();

      if (resultRegister.status) {
        const deviceToken = resultRegister.token;
        if (deviceToken) {
          localStorage.setItem('deviceToken', deviceToken);
          toast.success('Login  Success');

          window.location.href = '/wallet';
        } else {
        }
        toast.error(resultRegister.message);
      } else {
        toast.error(resultRegister.message);
      }
    } catch (error) {
      toast.error(error);
      
    }
    
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="header fixed-top">
        <div className="left"></div>
        <h3>LOGIN</h3>
      </div>

      <div className="app-content style-3">
        <div className="tf-container">
          <div className="mt-40 text-center banner-choose-wallet">
            <img className="lazyload" data-src="assets/images/background/bg-wallet-1.png" alt="img" />
          </div>

          <div className="mt-32">
            <h1 className="text-center">Login Here</h1>
            <p className="mt-20 body-3 text-dark-2 text-center px-24">Access Your Account!</p>
          </div>

          <form onSubmit={handleLogin} className="mt-32">

            {/* Email Input */}
            <div className="search-box mt-20 position-relative">
              <span className="icon-left">
                <FiMail size={20} style={{ opacity: 0.6 }} />
              </span>
              <input
                type="email"
                className="search-field"
                placeholder="Enter Email Here"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            {/* Password Input with show/hide functionality */}
            <div className="search-box mt-20 position-relative">
              <span className="icon-left">
                <FiLock size={20} style={{ opacity: 0.6 }} />
              </span>
              <input
                type={showPassword ? 'text' : 'password'}
                className="search-field"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* Toggle show/hide password icon */}
              <span
                className="password-toggle-icon"
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                }}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <FiEyeOff size={20} style={{ opacity: 0.6 }} />
                ) : (
                  <FiEye size={20} style={{ opacity: 0.6 }} />
                )}
              </span>
            </div>
            <div class="text-end text-danger mt-3">
                <a href="/resetpassword"> Forget Password</a>
            </div>

            <button type="submit" className="mt-32 tf-btn primary btn-lg">Login</button>
          </form>

          <div className="mt-24 d-flex justify-content-center gap-6">
            <p className="body-3">Don't have an account?</p>
            <a href="register" className="button-2 text-primary">Register</a>
          </div>
        </div>
      </div>

    </>
  );
}

export default Login;
