import React, { useEffect, useState } from 'react';
import Auth from '../component/auth';
function Directreferral() {
  const [withdrawTransaction, setWithdrawTransaction] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const deviceToken = localStorage.getItem('deviceToken');

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://api.cinu.io/api/myReferral', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${deviceToken}`, 
          },
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log(result);

        if (result.status) {
          setWithdrawTransaction(result.referrals); // Assuming Withdraw is the correct field
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [deviceToken]);

  return (
    <>
    <Auth/>
    <div className="header fixed-top">
    <div className="left">
   <a href="/stake-dashboard" className="icon back-btn">
     <i className="icon-arrow-right" />
   </a>
    </div>
        <h3>Direct Referral</h3>
      </div>
   <div className="app-content">
     <div className="tab-content mt-24">
       <div  role="tabpanel">
      <div className="px-16 mt-24">
        {loading ? (
          <div class="text-center" >
          <img src="assets/images/loadingIcon.gif" alt="Loading" style={{ width: '50%' }}/>
          </div>
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          <ul className="mt-24 list-view-v6 check-list">
            {withdrawTransaction.map((transaction, index) => (
              <li class="mt-10" key={index}>
                <a href="#" className="item active box-accordion">
                  <div 
                    className="box d-flex justify-content-between align-items-center gap-16 collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target={`#accordion-st-${index}`}
                    aria-expanded="false"
                    aria-controls={`accordion-st-${index}`} >
                    <div className="avatar round avt-48">
                      <img src="assets/images/avt/nft-logo-19.png" alt="NFT Logo" />
                    </div>
                    <div className="content d-flex justify-content-between">
                      <div className="content-left">
                        <p className="body-1 mt-2">{transaction.referral.name}</p>
                        <span className="more text-primary mt-2 button-3">Join Date : {transaction.referral.join_date}</span><br></br>
                        {transaction.referral.stakeStatus === 'active' ? (
                             <span className="more  mt-2 button-3">Status : <b class="text-success">Active</b></span>
                        ) : (
                          <span className="more mt-2 button-3">Status : <b class="text-danger">Pending</b></span>
                        )} 
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
     </div>
   </div>
   <div className="menubar-footer footer-fixed">
     <ul className="inner-bar">
       <li>
         <a href="/">
           <svg
             width={24}
             height={24}
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
           >
             <path
               d="M9.02 2.84016L3.63 7.04016C2.73 7.74016 2 9.23016 2 10.3602V17.7702C2 20.0902 3.89 21.9902 6.21 21.9902H17.79C20.11 21.9902 22 20.0902 22 17.7802V10.5002C22 9.29016 21.19 7.74016 20.2 7.05016L14.02 2.72016C12.62 1.74016 10.37 1.79016 9.02 2.84016Z"
               stroke="#1A1528"
               strokeWidth="1.5"
               strokeLinecap="round"
               strokeLinejoin="round"
             />
             <path
               d="M12 17.9902V14.9902"
               stroke="#1A1528"
               strokeWidth="1.5"
               strokeLinecap="round"
               strokeLinejoin="round"
             />
           </svg>
         </a>
       </li>
       <li className="active">
         <a href="/statistics">
           <svg
             width={24}
             height={24}
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
           >
             <path
               d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM17.26 9.96L14.95 12.94C14.66 13.31 14.25 13.55 13.78 13.6C13.31 13.66 12.85 13.53 12.48 13.24L10.65 11.8C10.58 11.74 10.5 11.74 10.46 11.75C10.42 11.75 10.35 11.77 10.29 11.85L7.91 14.94C7.76 15.13 7.54 15.23 7.32 15.23C7.16 15.23 7 15.18 6.86 15.07C6.53 14.82 6.47 14.35 6.72 14.02L9.1 10.93C9.39 10.56 9.8 10.32 10.27 10.26C10.73 10.2 11.2 10.33 11.57 10.62L13.4 12.06C13.47 12.12 13.54 12.12 13.59 12.11C13.63 12.11 13.7 12.09 13.76 12.01L16.07 9.03C16.32 8.7 16.8 8.64 17.12 8.9C17.45 9.17 17.51 9.64 17.26 9.96Z"
               fill="#7F52FF"
             />
           </svg>
           <span className="text">statistics</span>
         </a>
       </li>
       <li className="action-add-wallet">
            <a href="/wallet">
              {/* <i className="icon-plus" /> */}
              {/* <i className="bi bi-wallet2"></i> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-wallet2" viewBox="0 0 16 16">
                <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z" />
              </svg>
              {/* <img src="assets/images/wallet/wallet1.png"  alt="wallet image" /> */}

            </a>
          </li>
       <li>
         <a href="/profile">
           <svg
             width={24}
             height={24}
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
           >
             <g opacity="0.6">
               <path
                 d="M12.1596 10.87C12.0596 10.86 11.9396 10.86 11.8296 10.87C9.44957 10.79 7.55957 8.84 7.55957 6.44C7.55957 3.99 9.53957 2 11.9996 2C14.4496 2 16.4396 3.99 16.4396 6.44C16.4296 8.84 14.5396 10.79 12.1596 10.87Z"
                 stroke="#1A1528"
                 strokeWidth="1.5"
                 strokeLinecap="round"
                 strokeLinejoin="round"
               />
               <path
                 d="M7.15973 14.56C4.73973 16.18 4.73973 18.82 7.15973 20.43C9.90973 22.27 14.4197 22.27 17.1697 20.43C19.5897 18.81 19.5897 16.17 17.1697 14.56C14.4297 12.73 9.91973 12.73 7.15973 14.56Z"
                 stroke="#1A1528"
                 strokeWidth="1.5"
                 strokeLinecap="round"
                 strokeLinejoin="round"
               />
             </g>
           </svg>
         </a>
       </li>
       <li>
         <a href="/more">
           <svg
             width={24}
             height={24}
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
           >
             <g opacity="0.6">
               <path
                 d="M5 10H7C9 10 10 9 10 7V5C10 3 9 2 7 2H5C3 2 2 3 2 5V7C2 9 3 10 5 10Z"
                 stroke="#1A1528"
                 strokeWidth="1.5"
                 strokeMiterlimit={10}
                 strokeLinecap="round"
                 strokeLinejoin="round"
               />
               <path
                 d="M17 10H19C21 10 22 9 22 7V5C22 3 21 2 19 2H17C15 2 14 3 14 5V7C14 9 15 10 17 10Z"
                 stroke="#1A1528"
                 strokeWidth="1.5"
                 strokeMiterlimit={10}
                 strokeLinecap="round"
                 strokeLinejoin="round"
               />
               <path
                 d="M17 22H19C21 22 22 21 22 19V17C22 15 21 14 19 14H17C15 14 14 15 14 17V19C14 21 15 22 17 22Z"
                 stroke="#1A1528"
                 strokeWidth="1.5"
                 strokeMiterlimit={10}
                 strokeLinecap="round"
                 strokeLinejoin="round"
               />
               <path
                 d="M5 22H7C9 22 10 21 10 19V17C10 15 9 14 7 14H5C3 14 2 15 2 17V19C2 21 3 22 5 22Z"
                 stroke="#1A1528"
                 strokeWidth="1.5"
                 strokeMiterlimit={10}
                 strokeLinecap="round"
                 strokeLinejoin="round"
               />
             </g>
           </svg>
         </a>
       </li>
     </ul>
   </div>
    </>
  )
}

export default Directreferral