import React, { useEffect, useState } from 'react';
import Auth from '../component/auth';

function Web3txn() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const tokenName = localStorage.getItem('tokenName');
  const storedAddress = localStorage.getItem('walletAddress')
  

  useEffect(() => {

    const bsc_key = '3CSXMI8PF4JRRG2PSQ5RDCK7MSK8SZC6CK';
    const tokenContractAddress = localStorage.getItem('tokenContractAddress');

    
    
    // Fetch transaction data from the BscScan API
    const fetchTransactions = async () => {
      try {
        const response = await fetch(
          `https://api.bscscan.com/api?module=account&action=tokentx&contractaddress=${tokenContractAddress}&address=${storedAddress}&page=1&offset=10000&startblock=0&endblock=999999999&sort=desc&apikey=${bsc_key}`
        );
        const data = await response.json();
        if (data.status === "1") {
          setTransactions(data.result); // Set transactions if the API call is successful
        } else {
          setError('Failed to fetch transactions.');
        }
      } catch (error) {
        setError('Error fetching data.');
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  return (
    <>
    <Auth/>
    <div className="header fixed-top">
    <div className="left">
   <a href="/Wallet" className="icon back-btn">
     <i className="icon-arrow-right" />
   </a>
 </div>
     <h3>Transaction</h3>
   </div>
   <div className="app-content">
     <div className="tab-content mt-24">
       <div className="tab-pane fade active show" id="ranking" role="tabpanel">
         <div className="px-16 mt-24">
            {loading ? (
                <div class="text-center" >
                <img src="assets/images/loadingIcon.gif" alt="Loading" style={{ width: '50%' }}/>
                </div>
              ) : error ? (
                <p>{error}</p>
              ) : (
                <ul className="mt-24 list-view-v6 check-list">
                  {transactions.map((transaction, index) => (
                    <li key={transaction.hash} className="mt-20">
                      <a  href="javascript:void(0);"  className="item box-accordion gap-16">
                        <div
                          className="box d-flex justify-content-between align-items-center gap-16 collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target={`#accordion-${index}`}
                          aria-expanded="true"
                          aria-controls={`accordion-${index}`}
                        >
                          <div className="flex-grow-1 d-flex gap-16 align-items-center">
                            <div className="avatar round avt-48">
                              
                              {storedAddress.toUpperCase() == transaction.to.toUpperCase() ? (
                                    <img src="assets/images/avt/inIcon.png" alt="" />
                                ):(
                                    <img src="assets/images/avt/outIcon.png" alt="" />
                                
                                )}
                            </div>
                            <div className="content d-flex justify-content-between">
                              <div className="content-left">
                                <p className="button-1">Tx Hash: {transaction.hash.slice(0, 8)+'....'+transaction.hash.slice(-8)}</p>
                                {storedAddress.toUpperCase() == transaction.from.toUpperCase() ? (
                                    <span className="more text-primary mt-2 button-3">
                                    To:   {transaction.to.slice(0, 6)+'....'+transaction.to.slice(-6)}
                                  </span>
                                ):(
                                    <span className="more text-primary mt-2 button-3">
                                        From : {transaction.from.slice(0, 6)+'....'+transaction.from.slice(-6)}
                                </span>
                                )}

                                

                                 {storedAddress.toUpperCase() == transaction.to.toUpperCase() ? (
                                    <span className="less text-primary mt-2 button-3">
                                    To: {transaction.to.slice(0, 6)+'....'+transaction.to.slice(-6)}
                                  </span>
                                ):(
                                    <span className="less text-primary mt-2 button-3">
                                        From : {transaction.from.slice(0, 6)+'....'+transaction.from.slice(-6)}
                                </span>
                                )}


                                

                              </div>
                              <div className="content-right text-end">
                                <div className="mt-2 d-flex gap-4 align-items-center justify-content-end">
                                  
                                {storedAddress.toUpperCase() === transaction.to.toUpperCase() ? (
                                  <span className="button-2 text-success">
                                    +{(transaction.value / 1e18).toFixed(2)} {tokenName}
                                  </span>
                                ) : (
                                  <span className="button-2 text-danger">
                                    -{(transaction.value / 1e18).toFixed(2)} {tokenName}
                                  </span>
                                )}
                                </div>
                                <span className="mt-2 d-block body-4 text-dark-2">
                                  {new Date(transaction.timeStamp * 1000).toLocaleString()}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id={`accordion-${index}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`accordion-${index}`}
                        >
                          <ul className="body pt-16 line-t d-flex gap-40 justify-content-between">
                            <li>
                              <div className="body-5">Block Number</div>
                              <span className="button-3">{transaction.blockNumber}</span>
                            </li>
                            <li>
                              <div className="body-5">Gas Used</div>
                              <span className="button-3">{transaction.gasUsed}</span>
                            </li>
                            <li>
                            <a class="btn btn-primary btn-sm" href={`https://bscscan.com/tx/${transaction.hash}`} target="_blank" rel="noopener noreferrer">VIEW</a>

                            </li>
                          </ul>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              )}
         </div>
       </div>
       
     </div>
   </div>

   
    </>
  )
}

export default Web3txn