import React, { useState, useEffect } from 'react';
import { FiLock, FiMail } from 'react-icons/fi'; // Import icons from react-icons
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom'; // For navigation

function Resetpassword() {
  const [email, setEmail] = useState('');
  const [mailVerify, setMailVerify] = useState(false);
  const [showMail, setShowMail] = useState(true);
  const [password, setPassword] = useState("");
  const [confimPassowrd, setConPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [countdown, setCountdown] = useState(0); // Countdown state

  const handleEmail = async (e) => {
    e.preventDefault();

    localStorage.setItem('resetEmail', email);
    
    try {
      const email = localStorage.getItem('resetEmail');
      
      const postData = {
        email: email,
      };

      const responseEmail = await fetch('https://api.cinu.io/api/forget-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      // Check if the response is okay
      if (!responseEmail.ok) {
        const errorData = await responseEmail.json(); // Expecting a JSON error message
        toast.error(errorData.message || 'Failed to send OTP. Try again.');
        return;
      }

      const resultEmail = await responseEmail.json();

      if (resultEmail.status) {
        localStorage.setItem('resetEmail', email);
        toast.success(resultEmail.message); // Display success message
        setMailVerify(true);
        setShowMail(false);
        startCountdown(); // Start the countdown
      } else {
        toast.error(resultEmail.message); // Display error message from the response
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  const passwordReset = async (e) => {
    e.preventDefault();
    const email = localStorage.getItem('resetEmail');

    try {
      const postData = {
        email: email,
        otp: otp,
        password: password,
        confirm_password: confimPassowrd,
      };

      const responseReset = await fetch('https://api.cinu.io/api/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      // Check if the response is okay
      if (!responseReset.ok) {
        const errorData = await responseReset.json(); // Expecting a JSON error message
        toast.error(errorData.message || 'Failed to send OTP. Try again.');
        return;
      }

      const resultReset = await responseReset.json();

      if (resultReset.status) {
        localStorage.setItem('resetEmail', email);
        toast.success(resultReset.message); // Display success message

        setTimeout(() => {
          window.location.href = '/login';
        }, 5000);

      } else {
        toast.error(resultReset.message); // Display error message from the response
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  // Function to start the countdown
  const startCountdown = () => {
    setCountdown(20); // Set countdown to 20 seconds
    const timer = setInterval(() => {
      setCountdown(prev => {
        if (prev === 1) {
          clearInterval(timer); // Clear the timer when countdown ends
        }
        return prev - 1;
      });
    }, 1000);
  };

  return (
    <>
      <div className="header fixed-top">
        <div className="left"></div>
        <h3>RESET PASSWORD</h3>
      </div>

      {showMail && (
        <div className="app-content style-3">
          <div className="tf-container">
            <div className="mt-40 text-center banner-choose-wallet">
              <img src="assets/images/forgetPassword.avif" alt="Forget Password" /> {/* Lazy load replaced */}
            </div>

            <div className="mt-32">
              <h1 className="text-center">Reset Password</h1>
              <p className="mt-20 body-3 text-dark-2 text-center px-24">Enter Registered Email</p>
            </div>
            <form onSubmit={handleEmail} className="mt-32">
              {/* Email Input */}
              <div className="search-box mt-20 position-relative">
                <span className="icon-left">
                  <FiMail size={20} style={{ opacity: 0.6 }} />
                </span>
                <input
                  type="email"
                  className="search-field"
                  placeholder="Enter Email Here"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required // Adding basic HTML validation
                />
              </div>

              <button type="submit" className="mt-32 tf-btn primary btn-lg">REQUEST OTP</button>
            </form>

            <div className="mt-24 d-flex justify-content-center gap-6">
              <p className="body-3">Back to</p>
              <Link to="/login" className="button-2 text-primary">Login</Link> {/* Using React Router's Link */}
            </div>
          </div>
        </div>
      )}

      {mailVerify && (
        <div className="app-content style-3">
          <div className="tf-container">
            <div className="mt-40 text-center banner-choose-wallet">
              <img src="assets/images/forgetPassword.avif" alt="Forget Password" /> {/* Lazy load replaced */}
            </div>

            <div className="mt-10">
              <p className="mt-20 body-3 text-dark-2 text-center px-24">Reset Password</p>
            </div>
            <form className="mt-32">
              {/* Password Inputs */}
              <div className="search-box mt-20 position-relative">
                <span className="icon-left">
                  <FiLock size={20} style={{ opacity: 0.6 }} />
                </span>
                <input
                  type="password"
                  className="search-field"
                  placeholder="New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>

              <div className="search-box mt-20 position-relative">
                <span className="icon-left">
                  <FiLock size={20} style={{ opacity: 0.6 }} />
                </span>
                <input
                  type="password"
                  className="search-field"
                  placeholder="Confirm Password"
                  value={confimPassowrd}
                  onChange={(e) => setConPassword(e.target.value)}
                  required
                />
              </div>

              <div className="search-box mt-20 position-relative">
                <span className="icon-left">
                  <FiLock size={20} style={{ opacity: 0.6 }} />
                </span>
                <input
                  type="text"
                  className="search-field"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
              </div>

              <button onClick={passwordReset} className="mt-32 tf-btn primary btn-lg">SUBMIT</button>
              <div className="text-end mt-3">
                {countdown > 0 ? (
                  <span>Resend OTP in {countdown} seconds</span>
                ) : (
                  <a onClick={handleEmail}>Resend OTP</a>
                )}
              </div>
            </form>

            <div className="mt-24 d-flex justify-content-center gap-6">
              <p className="body-3">Back to</p>
              <Link to="/login" className="button-2 text-primary">Login</Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Resetpassword;
