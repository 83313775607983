import React, { useEffect, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { FaCopy, FaShareAlt } from 'react-icons/fa'; // Import icons from react-icons
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast from react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for react-toastify
import Auth from '../component/auth';

const Receive = () => {
  const [walletAddress, setWalletAddress] = useState('');

  useEffect(() => {
    // Retrieve the wallet address from local storage when the component mounts
    const storedAddress = localStorage.getItem('walletAddress');
    if (storedAddress) {
      setWalletAddress(storedAddress);

    }
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(walletAddress);
    toast.success('Wallet address copied to clipboard!');
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: ' Wallet Address',
        text: walletAddress+ ' Here is my wallet address:',
      }).catch((error) => console.log('Error sharing:', error));
    } else {
      toast.error('Sharing not supported on this browser.');
    }
  };

  return (
    <>
    <Auth/>
      <div className="header fixed-top">
        <div className="left">
          <a href="/Wallet" className="icon back-btn fs-12">
            <i className="icon-close2"></i>
          </a>
        </div>
        <h3>Receive Assets</h3>
      </div>
      <div className="app-content style-2">
        <div className="tf-container">
          <div className="text-center">
            <div className="box-wallet-1">
              <img src="assets/images/wallet/walletIcon.png" alt="img" />
              <span className="line"></span>
              <img src="assets/images/wallet/walletIcon.png" alt="img" />
            </div>
          </div>
          <div className="pb-24 mb-24 line">
            <h4 className="mt-24">Share Address or QR Code to Receive Assets!</h4>
            <p className="body-3 mt-12 text-dark-2">
              Make sure before sharing your QR code or address that it is a BEP-20 Binance Smart Chain address.
            </p>
          </div>
          <div className="text-center">
            <div className="box-wallet-1">
              <QRCodeSVG value={walletAddress} size={200} />
            </div>
          </div>

          <div className="pb-24 mb-24 line text-center">
            <h6 className="mt-24">{walletAddress.slice(0, 12)+'......'+walletAddress.slice(-12)}</h6>
            
          </div>
        </div>
      </div>

      <div className="btn-fixed">
        <div className="grid-2 gap-15">
          <a href="#" className="tf-btn disabled-primary" onClick={handleCopy}>
            <FaCopy /> COPY
          </a>
          <a href="#" className="tf-btn primary" onClick={handleShare}>
            <FaShareAlt /> SHARE
          </a>
        </div>
      </div>

      <ToastContainer /> {/* Add ToastContainer to render toast messages */}
    </>
  );
}

export default Receive;
